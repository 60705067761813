import React, { lazy, Suspense } from "react";

export const DynamicComponent = ({ componentName }) => {
  const Data = lazy(() => import(`../components/reactkits/${componentName}`));
  if (!componentName) {
    return <div>Error: componentName is undefined</div>;
  }

  const Component = `${componentName}`;

  if (!Component) {
    return <div>Error: Component {componentName} not found</div>;
  }

  return <Suspense fallback={<div>Loading...</div>}>{<Data />}</Suspense>;
};
