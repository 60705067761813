import { Box, Container, Grid } from "@mui/material";
import UIKitCard from "../components/UiKits/Card";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useState } from "react";

function Courses() {
  // Example data for courses
  const [tab, setTab] = useState(window.innerWidth > 768 ? true : false);
  const courseData = [
    {
      category: "HTML",
      cards: [
        {
          link: "/ui-kits/a",
          image:
            "https://www.hostinger.com/tutorials/wp-content/uploads/sites/2/2018/11/what-is-html-3.jpg",
          alt: "Image 1",
          categoryColor: "black",
          category: "News",
          date: "September 28th, 2022",
          title: "Laravel 9.32 Released",
        },
        {
          link: "/ui-kits/a",
          image:
            "https://www.hostinger.com/tutorials/wp-content/uploads/sites/2/2018/11/what-is-html-3.jpg",
          alt: "Image 1",
          categoryColor: "black",
          category: "News",
          date: "September 28th, 2022",
          title: "Laravel 9.32 Released",
        },
        {
          link: "/ui-kits/a",
          image:
            "https://www.hostinger.com/tutorials/wp-content/uploads/sites/2/2018/11/what-is-html-3.jpg",
          alt: "Image 1",
          categoryColor: "black",
          category: "News",
          date: "September 28th, 2022",
          title: "Laravel 9.32 Released",
        },
        {
          link: "/ui-kits/a",
          image:
            "https://www.hostinger.com/tutorials/wp-content/uploads/sites/2/2018/11/what-is-html-3.jpg",
          alt: "Image 1",
          categoryColor: "black",
          category: "News",
          date: "September 28th, 2022",
          title: "Laravel 9.32 Released",
        },
        {
          link: "/ui-kits/a",
          image:
            "https://www.hostinger.com/tutorials/wp-content/uploads/sites/2/2018/11/what-is-html-3.jpg",
          alt: "Image 1",
          categoryColor: "black",
          category: "News",
          date: "September 28th, 2022",
          title: "Laravel 9.32 Released",
        },
        {
          link: "/ui-kits/b",
          image:
            "https://www.hostinger.com/tutorials/wp-content/uploads/sites/2/2018/11/what-is-html-3.jpg",
          alt: "Image 2",
          categoryColor: "",
          category: "Tutorial",
          date: "October 15th, 2022",
          title: "React Hooks Tutorial",
        },
      ],
    },
    {
      category: "JavaScript",
      cards: [
        {
          link: "/ui-kits/c",
          image:
            "https://www.hostinger.com/tutorials/wp-content/uploads/sites/2/2018/11/what-is-html-3.jpg",
          alt: "Image 3",
          categoryColor: "black",
          category: "Update",
          date: "November 5th, 2022",
          title: "Node.js 16.8 Update",
        },
        {
          link: "/ui-kits/d",
          image:
            "https://www.hostinger.com/tutorials/wp-content/uploads/sites/2/2018/11/what-is-html-3.jpg",
          alt: "Image 4",
          categoryColor: "black",
          category: "Event",
          date: "December 20th, 2022",
          title: "JavaScript Conference 2023 Announcement",
        },
      ],
    },
    // Add more categories as needed
  ];

  // Carousel responsive settings
  const responsiveSettings = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Box component={"main"}>
      <Container maxWidth={"xl"} className="pt-10">
        <h1 className="text-2xl font-extrabold">Browse Courses</h1>

        {/* Mapping through courseData */}
        {courseData?.map((category, index) => (
          <div key={index} className="tag-container my-5">
            <h3 className="text-xl font-bold mb-3">{category.category}</h3>
            {tab ? (
              <Grid container spacing={3} justifyContent={"stretch"}>
                {category?.cards?.map((card, cardIndex) => (
                  <Grid item key={cardIndex} sm={6} lg={3}>
                    <UIKitCard
                      link={card?.link}
                      image={card?.image}
                      alt={card?.alt}
                      categoryColor={card?.categoryColor}
                      category={card?.category}
                      date={card?.date}
                      title={card?.title}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Carousel
                responsive={responsiveSettings}
                swipeable={true}
                draggable={true}
                showDots={true}
                infinite={true}
                autoPlay={false}
                keyBoardControl={true}
              >
                {category?.cards?.map((card, cardIndex) => (
                  <div key={cardIndex} className="mx-2">
                    <UIKitCard
                      link={card?.link}
                      image={card?.image}
                      alt={card?.alt}
                      categoryColor={card?.categoryColor}
                      category={card?.category}
                      date={card?.date}
                      title={card?.title}
                    />
                  </div>
                ))}
              </Carousel>
            )}
          </div>
        ))}
      </Container>
    </Box>
  );
}

export default Courses;
