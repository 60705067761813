import React, { useEffect, useState } from "react";

import axios from "axios";
import { Button, IconButton, LinearProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { PhotoCamera } from "@mui/icons-material";

import "./upload.css";
import { useDispatch } from "react-redux";
import { deleteFile } from "../redux/slices/fileUploadSlice";

const ImageUpload = ({ id, name, setFieldValue, setMsg, setSnack, imgSrc }) => {
  const [image, setImage] = useState();
  const [uploadProgress, setUploadProgress] = useState(0);
  useEffect(() => {
    setImage(imgSrc);
    setFieldValue("image", imgSrc);
  }, [imgSrc]);
  console.log(imgSrc);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
      uploadFile(file);
    }
  };
  const dispatch = useDispatch();
  console.log(imgSrc);

  const handleDeleteImage = async () => {
    setImage(null);
    setUploadProgress(0); // Reset progress on delete
    setFieldValue("image", null);
    await dispatch(
      deleteFile({ queryParams: imgSrc.split("uploads/")[1] })
    ).then((data) => {
      setSnack(true);
      setMsg("File Deleted !");
    });
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        process.env.NODE_ENV === "development"
          ? "http://localhost:4000/api/file/upload"
          : process.env.REACT_APP_API_URL + "/file/upload",
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            setUploadProgress(progress);
          },
        }
      );

      if (response.data) {
        setFieldValue("image", response.data.data.fileUrl);
        setSnack(true);
        setMsg("File uploaded successfully!");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <div className="root">
      {image && (
        <div className="w-[90%]">
          <IconButton
            className="relative w-full"
            onClick={handleDeleteImage}
            color="secondary"
          >
            <div className="bg-white p-3 flex justify-center items-center shadow-lg w-full h-[180px]">
              <img src={image} className="imagePreview" alt="Preview" />
            </div>
            <CloseIcon className="absolute top-[20px] right-[20px]" />
          </IconButton>
          <LinearProgress
            variant="determinate"
            className="progress"
            value={uploadProgress}
          />
        </div>
      )}
      <input
        accept="image/*"
        id={id}
        name={name}
        type="file"
        onChange={handleImageChange}
        className="input"
      />
      <label htmlFor="icon-button-file" className="flex justify-center mt-3">
        <Button
          variant="contained"
          color="primary"
          component="span"
          startIcon={<PhotoCamera />}
        >
          Upload Image
        </Button>
      </label>
    </div>
  );
};

export default ImageUpload;
