import { Box, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import * as React from "react";
import Drawer from "@mui/material/Drawer";
import Editor from "@monaco-editor/react";
import Frame from "react-frame-component";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";

import JsxParser from "react-jsx-parser";
import TabletMacIcon from "@mui/icons-material/TabletMac";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import { useDispatch } from "react-redux";
import { fetchCb, fetchCbCategorieById } from "../redux/slices/codeBlockSlice";
import { Link, useLocation, useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { tab } from "@testing-library/user-event/dist/tab";
import { DynamicComponent } from "../components/DynamicComponent";
import { createReactEditorJS } from "react-editor-js";
import { ReactEditor } from "../components/ReactEditor";

export default function UiKitDetails() {
  const params = useParams();
  const ReactEditorJS = createReactEditorJS();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const dispatch = useDispatch();
  const [value, setValue] = useState();
  const [pageCategories, setPageCategories] = useState([]);

  const [pageData, setPageData] = useState();
  const location = useLocation();
  React.useEffect(() => {
    if (location.pathname.split("/").length > 1) {
      dispatch(fetchCb({ queryParams: params.slug }))
        .then(unwrapResult)
        .then(async (data) => {
          setValue(data?.code);
          setPageData(data);
          await dispatch(fetchCbCategorieById({ queryParams: data.category }))
            .then(unwrapResult)
            .then((data) => {
              setPageCategories(data);
            });
        });
    }
  }, [location?.pathname, dispatch]);
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Typography
        component={"div"}
        fontSize={22}
        fontWeight={"bold"}
        className="ps-5 p-5 text-center"
      >
        Hero
      </Typography>
      <Divider />
      <List>
        {pageCategories?.components?.map((item) => (
          <Link key={item.name} to={`/ui-kit/${item.slug}`}>
            <ListItem disablePadding>
              <ListItemButton>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <img src={item?.image} alt="ok" />
                  <p> {item.name}</p>
                </div>
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  );
  const [show, setShow] = useState(false);
  const onChange = React.useCallback((val, viewUpdate) => {
    setValue(val);
  }, []);
  const [device, setDevice] = useState("100%");
  return (
    <>
      <title>UI Kits</title>
      <meta
        name="keywords"
        content="ui kits, developers corner, developer corner, tutorials"
      />
      <link
        rel="canonical"
        href={`https://www.developerscorner.in/ui-kit/${params.slug}`}
      />

      <Box component={"main"} overflow={"hidden"}>
        <Box sx={{ display: "flex" }}>
          <Drawer
            anchor={"left"}
            style={{ position: "relative" }}
            variant="temporary"
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
            sx={{
              display: { xs: "none", sm: "block", zIndex: 0 },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: 255,
              },
            }}
          >
            {list("Open SideBar")}
          </Drawer>
          <Drawer
            style={{ position: "relative" }}
            anchor={"left"}
            onClose={toggleDrawer("left", false)}
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block", zIndex: 0 },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: 255,
                position: "relative",
              },
            }}
            open
          >
            {list("Open SideBar")}
          </Drawer>
          <Box width={"100%"} sx={{ padding: 1.6 }}>
            <div>
              <div className="flex justify-between gap-3 px-3 bg-black rounded-md items-center mb-3">
                <h1 className="text-white capitalize">{pageData?.name}</h1>
                <div className="flex gap-3 btns   py-3">
                  {!show && (
                    <div className="flex gap-3 items-center">
                      <IconButton
                        className=" !hidden lg:!block"
                        color="primary"
                        size="20"
                        style={{ padding: 0 }}
                      >
                        <WebAssetIcon
                          onClick={() => setDevice("100%")}
                          fontSize="large"
                        />
                      </IconButton>
                      <IconButton
                        className=" !hidden md:!block"
                        color="primary"
                        size="20"
                        style={{ padding: 0 }}
                      >
                        <TabletMacIcon
                          onClick={() => setDevice(tab ? "100%" : "820px")}
                        />
                      </IconButton>
                      <IconButton
                        className=" !hidden md:!block"
                        color="primary"
                        size="20"
                        style={{ padding: 0 }}
                      >
                        <PhoneAndroidIcon onClick={() => setDevice("360px")} />
                      </IconButton>
                    </div>
                  )}
                  <Button
                    variant={show ? "outlined" : "contained"}
                    onClick={() => {
                      setShow(false);
                    }}
                  >
                    Preview
                  </Button>
                  <Button
                    variant={!show ? "outlined" : "contained"}
                    onClick={() => {
                      setShow(true);
                    }}
                  >
                    Code
                  </Button>
                </div>
              </div>
              <div>
                {!show && (
                  <div className="preview  flex justify-center items-center mx-auto ">
                    {pageData?.language === "react" ? (
                      <div className="py-5">
                        <DynamicComponent
                          componentName={pageData?.componentName}
                        />
                      </div>
                    ) : (
                      <Frame
                        className="border border-8 rounded-md"
                        height={550}
                        width={device}
                        initialContent="<!DOCTYPE html><html><head>
                        <meta charset='UTF-8' />
                        <meta
                          name='viewport'
                          content='width=device-width, initial-scale=1.0'
                        />
                        <link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/highlight.js@10.8.0/styles/default.min.css'>
                       <script src='https://cdn.jsdelivr.net/npm/highlight.js@10.8.0/lib/highlight.min.js'></script>
                        <script src='https://cdn.tailwindcss.com?plugins=forms,typography,aspect-ratio,line-clamp,container-queries'></script>
                         <script>document.addEventListener('DOMContentLoaded', (event) => {document.querySelectorAll('pre code').forEach((block) => {hljs.highlightBlock(block);})})</script>
                      </head><body className='p-2'><div id='mountHere'></div>
                       </body></html>"
                        mountTarget="#mountHere"
                      >
                        <JsxParser
                          showWarnings
                          autoCloseVoidElements
                          allowUnknownElements
                          jsx={value ? value : ""}
                        />
                      </Frame>
                    )}
                  </div>
                )}
                {show && (
                  <div className="code px-2">
                    {pageData?.language === "html" ? (
                      <Editor
                        height="90vh"
                        onChange={onChange}
                        defaultLanguage="javascript"
                        value={value || ""}
                      />
                    ) : (
                      <ReactEditor data={pageData?.code} />
                    )}
                  </div>
                )}
              </div>
            </div>
          </Box>
        </Box>
      </Box>
    </>
  );
}
