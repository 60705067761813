import {
  Alert,
  Container,
  Drawer,
  Snackbar,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Box,
} from "@mui/material";

import TableComponent from "../../components/Table/TableComponent";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import JoditEditor from "jodit-react";

import { fetchTC } from "../../redux/slices/tutorialCategorySlice";
import {
  createproject,
  deleteP,
  fetchP,
  fetchPbyId,
  updateP,
} from "../../redux/slices/projectSlice";
import { fetchPC } from "../../redux/slices/projectCatSlice";

const formSchema = Yup.object().shape({
  title: Yup.string().trim().required("Title is required"),
  category: Yup.string().trim().required("Category is required"),
  desc: Yup.string().trim().required("Description is required"),
  keywords: Yup.string().trim().required("Keywords are required"),
  content: Yup.string().trim().required("Content is required"),
  links: Yup.array().of(Yup.object()),
  images: Yup.array().of(Yup.string().trim().url("Invalid URL")),
  author: Yup.string().trim(),
  price: Yup.number().min(0, "Price must be at least 0"),
  priceAfterDiscount: Yup.number().min(
    0,
    "Price after discount must be at least 0"
  ),
  techStack: Yup.string().trim(),
});

export const DbProjects = () => {
  const [uiKits, setUiKits] = useState([]);
  const [uiKitCategories, setUiKitCategories] = useState([]);
  const [updateCodeBlocks, setUpdateCodeBlocks] = useState(0);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [snack, setSnack] = useState(false);
  const [msg, setMsg] = useState("");
  const [openCodeBlock, setOpenCodeBlock] = useState(false);
  const [edit, setEditMode] = useState(false);
  const editor = useRef(null);

  useEffect(() => {
    dispatch(fetchP())
      .then(unwrapResult)
      .then((result) => {
        const filterObject = (obj) => {
          return Object.keys(obj)
            .filter((key) => !excludeKeys.includes(key))
            .reduce((acc, key) => {
              acc[key] = obj[key];
              return acc;
            }, {});
        };

        setUiKits(result?.data?.map(filterObject));
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
    dispatch(fetchPC())
      .then(unwrapResult)
      .then((result) => {
        setUiKitCategories(result.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [updateCodeBlocks]);
  console.log(uiKits);

  const excludeKeys = [
    "image_url",
    "slug",
    "__v",
    "updatedAt",
    "content",
    "image",
    "code",
    "tutorialCategorySlug",
    "keywords",
    "desc",
    "links",
    "images",
    "priceAfterDiscount",
    "price",
    "author",
  ];
  const headers = [
    { id: "_id", label: "_ID" },
    { id: "title", label: "Title" },
    { id: "category", label: "Category" },
    { id: "desc", label: "Description" },
    { id: "createdAt", label: "Created At" },
  ];

  const onDelete = async (id) => {
    await dispatch(
      deleteP({
        queryParams: id,
      })
    )
      .then(unwrapResult)
      .then(() => {
        setSnack(true);
        setMsg("Project Deleted!");
        setUpdateCodeBlocks(updateCodeBlocks + 1);
      });
  };

  const [editId, setEditId] = useState();
  const getPById = async (editId) => {
    await dispatch(fetchPbyId({ queryParams: `id/${editId}` }))
      .then(unwrapResult)
      .then((data) => {
        setData(data.data);
      });
  };

  const onEdit = (id) => {
    getPById(id);
    setEditMode(true);
    setOpenCodeBlock(true);
    setEditId(id);
  };

  return (
    <Box component={"main"}>
      <Container maxWidth={"xl"}>
        <Snackbar
          open={snack}
          onClose={() => setSnack(false)}
          autoHideDuration={5000}
        >
          <Alert
            onClose={() => setSnack(false)}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {msg}
          </Alert>
        </Snackbar>

        <Drawer
          open={openCodeBlock}
          anchor="right"
          onClose={() => setOpenCodeBlock(false)}
        >
          <div className="flex">
            <div className="w-full md:w-[600px] p-4 md:p-8 rounded-lg">
              <div className="flex mb-6 justify-between items-center">
                <h2 className="text-md md:text-2xl font-bold text-center">
                  {edit ? "Edit" : "Create New"} Project
                </h2>
                <div
                  onClick={() => setOpenCodeBlock(false)}
                  className="w-[15px] h-[15px]"
                >
                  <svg
                    fill="#000000"
                    height="15px"
                    width="15px"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 490 490"
                  >
                    <polygon points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490 489.292,457.678 277.331,245.004 489.292,32.337 "></polygon>
                  </svg>
                </div>
              </div>
              <Formik
                initialValues={{
                  title: data?.title || "",
                  category: data?.category || "",
                  desc: data?.desc || "",
                  keywords: data?.keywords || "",
                  content: data?.content || "",
                  links: data?.links?.length
                    ? data?.links
                    : [{ name: "", url: "" }],
                  images: data?.images?.length ? data?.images : [""],
                  author: data?.author || "Developer's Corner",
                  price: data?.price || 0,
                  priceAfterDiscount: data?.priceAfterDiscount || 0,
                  techStack: data?.techStack || "",
                }}
                validationSchema={formSchema}
                enableReinitialize={edit}
                onSubmit={async (values) => {
                  if (edit) {
                    await dispatch(
                      updateP({ data: values, queryParams: editId })
                    ).then((data) => {
                      setSnack(true);
                      setMsg("Project Updated!");
                      setOpenCodeBlock(false);
                      setUpdateCodeBlocks(updateCodeBlocks + 1);
                    });
                  } else {
                    await dispatch(createproject({ data: values })).then(
                      (data) => {
                        setSnack(true);
                        setMsg("Project Added!");
                        setOpenCodeBlock(false);
                        setUpdateCodeBlocks(updateCodeBlocks + 1);
                      }
                    );
                  }
                }}
              >
                {({ errors, touched, setFieldValue, values }) => (
                  <Form>
                    <div className="mb-4">
                      <label
                        htmlFor="title"
                        className="block mb-2 text-sm font-medium text-gray-700"
                      >
                        Title
                      </label>
                      <Field
                        name="title"
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                      <ErrorMessage
                        name="title"
                        component="div"
                        className="mt-2 text-sm text-red-600"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="category"
                        className="block mb-2 text-sm font-medium text-gray-700"
                      >
                        Category
                      </label>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="category">Category</InputLabel>
                        <Field
                          name="category"
                          as={Select}
                          label="Category"
                          onChange={(event) =>
                            setFieldValue("category", event.target.value)
                          }
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {uiKitCategories?.map((item) => (
                            <MenuItem key={item._id} value={item.title}>
                              {item.title}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormControl>
                      <ErrorMessage
                        name="category"
                        component="div"
                        className="mt-2 text-sm text-red-600"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="desc"
                        className="block mb-2 text-sm font-medium text-gray-700"
                      >
                        Description
                      </label>
                      <Field
                        name="desc"
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                      <ErrorMessage
                        name="desc"
                        component="div"
                        className="mt-2 text-sm text-red-600"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="content"
                        className="block mb-2 text-sm font-medium text-gray-700"
                      >
                        Content
                      </label>
                      <JoditEditor
                        value={values.content}
                        tabIndex={1}
                        onBlur={(newContent) =>
                          setFieldValue("content", newContent)
                        }
                        onChange={(newContent) => {}}
                        className="border h-[300px] border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                      <ErrorMessage
                        name="content"
                        component="div"
                        className="mt-2 text-sm text-red-600"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="keywords"
                        className="block mb-2 text-sm font-medium text-gray-700"
                      >
                        Keywords
                      </label>
                      <Field
                        name="keywords"
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                      <ErrorMessage
                        name="keywords"
                        component="div"
                        className="mt-2 text-sm text-red-600"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="links"
                        className="block mb-2 text-sm font-medium text-gray-700"
                      >
                        Links
                      </label>
                      <FieldArray name="links">
                        {({ remove, push }) => (
                          <>
                            {values.links.map((link, index) => (
                              <div key={index} className="flex space-x-2 mb-2">
                                <Field
                                  name={`links.${index}.name`}
                                  type="text"
                                  placeholder="Name"
                                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                />
                                <Field
                                  name={`links.${index}.url`}
                                  type="url"
                                  placeholder="URL"
                                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                />
                                <button
                                  type="button"
                                  onClick={() => remove(index)}
                                  className="px-4 py-2 bg-red-500 text-white rounded-md"
                                >
                                  Remove
                                </button>
                              </div>
                            ))}
                            <button
                              type="button"
                              onClick={() => push({ name: "", url: "" })}
                              className="px-4 py-2 bg-blue-500 text-white rounded-md"
                            >
                              Add Link
                            </button>
                          </>
                        )}
                      </FieldArray>
                      <ErrorMessage
                        name="links"
                        component="div"
                        className="mt-2 text-sm text-red-600"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="images"
                        className="block mb-2 text-sm font-medium text-gray-700"
                      >
                        Images
                      </label>
                      <FieldArray name="images">
                        {({ remove, push }) => (
                          <>
                            {values.images.map((image, index) => (
                              <div key={index} className="flex space-x-2 mb-2">
                                <Field
                                  name={`images.${index}`}
                                  type="url"
                                  placeholder="Image URL"
                                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                />
                                <button
                                  type="button"
                                  onClick={() => remove(index)}
                                  className="px-4 py-2 bg-red-500 text-white rounded-md"
                                >
                                  Remove
                                </button>
                              </div>
                            ))}
                            <button
                              type="button"
                              onClick={() => push("")}
                              className="px-4 py-2 bg-blue-500 text-white rounded-md"
                            >
                              Add Image
                            </button>
                          </>
                        )}
                      </FieldArray>
                      <ErrorMessage
                        name="images"
                        component="div"
                        className="mt-2 text-sm text-red-600"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="author"
                        className="block mb-2 text-sm font-medium text-gray-700"
                      >
                        Author
                      </label>
                      <Field
                        name="author"
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                      <ErrorMessage
                        name="author"
                        component="div"
                        className="mt-2 text-sm text-red-600"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="price"
                        className="block mb-2 text-sm font-medium text-gray-700"
                      >
                        Price
                      </label>
                      <Field
                        name="price"
                        type="number"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                      <ErrorMessage
                        name="price"
                        component="div"
                        className="mt-2 text-sm text-red-600"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="priceAfterDiscount"
                        className="block mb-2 text-sm font-medium text-gray-700"
                      >
                        Price After Discount
                      </label>
                      <Field
                        name="priceAfterDiscount"
                        type="number"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                      <ErrorMessage
                        name="priceAfterDiscount"
                        component="div"
                        className="mt-2 text-sm text-red-600"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="techStack"
                        className="block mb-2 text-sm font-medium text-gray-700"
                      >
                        Tech Stack
                      </label>
                      <Field
                        name="techStack"
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                      <ErrorMessage
                        name="techStack"
                        component="div"
                        className="mt-2 text-sm text-red-600"
                      />
                    </div>

                    <div className="flex items-center justify-start">
                      <button
                        type="submit"
                        className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      >
                        {edit ? "Update" : "Add"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Drawer>
        <div className="flex items-center justify-between">
          <h3 className="text-lg md:text-3xl font-bold my-5">List Projects</h3>
          <h6
            onClick={() => {
              setData();
              setEditMode(false);
              setOpenCodeBlock(true);
            }}
            className="font-bold rounded-md cp text-white"
          >
            <img src="/add.svg" width={30} alt="" />
          </h6>
        </div>
        <TableComponent
          initialData={uiKits || []}
          headers={headers}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </Container>
    </Box>
  );
};
