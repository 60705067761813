// RegistrationForm.js
import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  FaEnvelope,
  FaLock,
  FaUser,
  FaMobileAlt,
  FaBriefcase,
  FaSpinner,
  FaEye,
  FaEyeSlash,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createUser } from "../redux/slices/userSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import CustomSnackbar from "../components/CustomSnackbar";
import { Box, Container } from "@mui/material";

const RegistrationForm = () => {
  // State to toggle password visibility
  const [showPassword, setShowPassword] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [msg, setMsg] = useState(false);
  const [err, setErr] = useState("success");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required("First name is required"),
    lastname: Yup.string().required("Last name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    mobile: Yup.string().required("Mobile number is required"),
    password: Yup.string().required("Password is required"),
    roles: Yup.string()
      .oneOf(["learner", "instructor", "company"], "Invalid role")
      .required("Role is required"),
    profession: Yup.string(),
  });

  // Form submission handler
  const handleSubmit = async (values, { setSubmitting }) => {
    await dispatch(createUser({ data: values }))
      .then(unwrapResult)
      .then((data) => {
        if (data.status) {
          setMsg(data?.message);
          setSnackOpen(true);
          navigate("/login");
        } else {
          setMsg(data?.message);
          setSnackOpen(true);
          setErr("error");
        }
        setSubmitting(false);
      });
  };

  return (
    <Box component={"main"}>
      <Container maxWidth={"xl"}>
        <div className="flex justify-center items-center py-12 ">
          <CustomSnackbar
            open={snackOpen}
            onClose={() => setSnackOpen(false)}
            message={msg}
            severity={err}
          />
          <Formik
            initialValues={{
              firstname: "",
              lastname: "",
              email: "",
              mobile: "",
              password: "",
              roles: "learner",
              profession: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="sm:bg-white sm:p-8 rounded-lg sm:shadow-lg w-96">
                <h2 className="text-3xl font-bold mb-6 text-indigo-600">
                  Register
                </h2>

                <div className="mb-4 relative">
                  <label
                    htmlFor="firstname"
                    className="block text-gray-700 font-semibold mb-2"
                  >
                    First Name
                  </label>
                  <div className="flex items-center border rounded-lg">
                    <FaUser className="mx-3 text-gray-400" />
                    <Field
                      type="text"
                      id="firstname"
                      name="firstname"
                      placeholder="Enter your first name"
                      className="w-full px-3 py-2 outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                  </div>
                  <ErrorMessage
                    name="firstname"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div className="mb-4 relative">
                  <label
                    htmlFor="lastname"
                    className="block text-gray-700 font-semibold mb-2"
                  >
                    Last Name
                  </label>
                  <div className="flex items-center border rounded-lg">
                    <FaUser className="mx-3 text-gray-400" />
                    <Field
                      type="text"
                      id="lastname"
                      name="lastname"
                      placeholder="Enter your last name"
                      className="w-full px-3 py-2 outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                  </div>
                  <ErrorMessage
                    name="lastname"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div className="mb-4 relative">
                  <label
                    htmlFor="email"
                    className="block text-gray-700 font-semibold mb-2"
                  >
                    Email
                  </label>
                  <div className="flex items-center border rounded-lg">
                    <FaEnvelope className="mx-3 text-gray-400" />
                    <Field
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Enter your email"
                      className="w-full px-3 py-2 outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                  </div>
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div className="mb-4 relative">
                  <label
                    htmlFor="mobile"
                    className="block text-gray-700 font-semibold mb-2"
                  >
                    Mobile
                  </label>
                  <div className="flex items-center border rounded-lg">
                    <FaMobileAlt className="mx-3 text-gray-400" />
                    <Field
                      type="text"
                      id="mobile"
                      name="mobile"
                      placeholder="Enter your mobile number"
                      className="w-full px-3 py-2 outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                  </div>
                  <ErrorMessage
                    name="mobile"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div className="mb-6 relative">
                  <label
                    htmlFor="password"
                    className="block text-gray-700 font-semibold mb-2"
                  >
                    Password
                  </label>
                  <div className="flex items-center border rounded-lg relative">
                    <FaLock className="mx-3 text-gray-400" />
                    <Field
                      type={showPassword ? "text" : "password"}
                      id="password"
                      name="password"
                      placeholder="Enter your password"
                      className="w-full px-3 py-2 outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                    <button
                      type="button"
                      className="absolute right-3 text-gray-400 focus:outline-none"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  </div>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="roles"
                    className="block text-gray-700 font-semibold mb-2"
                  >
                    Role
                  </label>
                  <Field
                    as="select"
                    id="roles"
                    name="roles"
                    className="w-full px-3 py-2 border rounded-lg outline-none focus:ring-2 focus:ring-indigo-500"
                  >
                    <option value="learner">Learner</option>
                    <option value="instructor">Instructor</option>
                    <option value="company">Company</option>
                  </Field>
                  <ErrorMessage
                    name="roles"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div className="mb-4 relative">
                  <label
                    htmlFor="profession"
                    className="block text-gray-700 font-semibold mb-2"
                  >
                    Profession
                  </label>
                  <div className="flex items-center border rounded-lg">
                    <FaBriefcase className="mx-3 text-gray-400" />
                    <Field
                      type="text"
                      id="profession"
                      name="profession"
                      placeholder="Enter your profession (optional)"
                      className="w-full px-3 py-2 outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                  </div>
                  <ErrorMessage
                    name="profession"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full bg-indigo-600 text-white py-3 px-4 rounded-lg font-semibold transition duration-300 ease-in-out hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:outline-none flex justify-center items-center"
                >
                  {isSubmitting ? (
                    <FaSpinner className="animate-spin mr-2" />
                  ) : (
                    "Register"
                  )}
                  {isSubmitting && "Registering..."}
                </button>

                <div className="mt-4 text-center">
                  <span className="text-sm text-gray-600">
                    Already registered?{" "}
                    <Link
                      to={"/login"}
                      className="text-indigo-600 hover:underline"
                    >
                      Login here
                    </Link>
                  </span>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </Box>
  );
};

export default RegistrationForm;
