import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css"; //Example style, you can use another
export const ReactEditor = ({ data }) => {
  console.log(data);

  return (
    <Editor highlight={(code) => highlight(code, languages.js)} value={data} />
  );
};
