import { Box, Container } from "@mui/material";

export default function Privacy() {
  return (
    <>
      <title>Privacy</title>
      <meta name="keywords" content="developers corner, developer corner" />
      <link rel="canonical" href={window.location.href} />

      <Box component={"main"}>
        <Container maxWidth={"xl"}>
          <div className="mx-auto py-12 ">
            <h1 className="text-4xl font-bold mb-6 text-indigo-600">
              Privacy Policy
            </h1>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-4 text-indigo-600">
                Introduction
              </h2>
              <p>
                Welcome to Developer's Corner. We are committed to protecting
                your privacy and ensuring that your personal information is
                handled in a safe and responsible manner. This Privacy Policy
                outlines how we collect, use, and protect your information.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-4 text-indigo-600">
                Information We Collect
              </h2>
              <p className="mb-4">
                We collect various types of information in connection with the
                services we provide, including:
              </p>
              <ul className="list-disc list-inside mb-4">
                <li>
                  <strong>Personal Information:</strong> Such as your name,
                  email address, and contact details when you register for an
                  account or subscribe to our newsletter.
                </li>
                <li>
                  <strong>Usage Data:</strong> Information on how you interact
                  with our website, including your IP address, browser type, and
                  browsing history.
                </li>
                <li>
                  <strong>Cookies and Tracking Technologies:</strong> We use
                  cookies to enhance your experience and collect information
                  about how you use our services.
                </li>
              </ul>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-4 text-indigo-600">
                How We Use Your Information
              </h2>
              <p className="mb-4">
                We use the information we collect for various purposes,
                including:
              </p>
              <ul className="list-disc list-inside mb-4">
                <li>Providing, maintaining, and improving our services.</li>
                <li>Personalizing your experience on our website.</li>
                <li>
                  Communicating with you, including sending promotional
                  materials and updates.
                </li>
                <li>
                  Analyzing usage patterns to improve our content and offerings.
                </li>
                <li>
                  Ensuring the security of our website and protecting against
                  unauthorized access.
                </li>
              </ul>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-4 text-indigo-600">
                Sharing Your Information
              </h2>
              <p className="mb-4">
                We do not sell, trade, or otherwise transfer your personal
                information to outside parties except in the following
                circumstances:
              </p>
              <ul className="list-disc list-inside mb-4">
                <li>With your consent.</li>
                <li>
                  To trusted third parties who assist us in operating our
                  website and conducting our business, so long as those parties
                  agree to keep this information confidential.
                </li>
                <li>
                  When we believe release is appropriate to comply with the law,
                  enforce our site policies, or protect ours or others' rights,
                  property, or safety.
                </li>
              </ul>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-4 text-indigo-600">
                Security of Your Information
              </h2>
              <p className="mb-4">
                We implement a variety of security measures to maintain the
                safety of your personal information. However, no method of
                transmission over the internet, or method of electronic storage,
                is 100% secure, and we cannot guarantee its absolute security.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-4 text-indigo-600">
                Your Rights
              </h2>
              <p className="mb-4">
                You have the right to access, correct, or delete your personal
                information. You may also have the right to object to or
                restrict certain types of processing of your personal
                information. To exercise these rights, please contact us at the
                information provided below.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-4 text-indigo-600">
                Third-Party Links
              </h2>
              <p className="mb-4">
                Our website may contain links to third-party sites. These
                third-party sites have separate and independent privacy
                policies. We therefore have no responsibility or liability for
                the content and activities of these linked sites. Nonetheless,
                we seek to protect the integrity of our site and welcome any
                feedback about these sites.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-4 text-indigo-600">
                Changes to Our Privacy Policy
              </h2>
              <p className="mb-4">
                We may update this Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page. You are advised to review this Privacy Policy
                periodically for any changes. Changes to this Privacy Policy are
                effective when they are posted on this page.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-4 text-indigo-600">
                Contact Us
              </h2>
              <p>
                If you have any questions about this Privacy Policy, please
                contact us:
              </p>
              <p>
                <strong>Email:</strong> support@developerscorner.com
              </p>
              <p>
                <strong>Address:</strong> 123 Developer Lane, Code City, Dev
                State, 45678
              </p>
            </section>
          </div>
        </Container>
      </Box>
    </>
  );
}
