// PrivateRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ children }) => {
  const token = useSelector((state) => state.user.token !== null);
  const role = useSelector((state) => state.user.role === "admin");

  return token && role ? children : <Navigate to="/login" />;
};
export default PrivateRoute;
