import { Box, Container, Grid, Skeleton } from "@mui/material";
import UIKitCard from "../components/UiKits/Card";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { getVideos } from "../redux/slices/videoSlice";

function Videos() {
  // Example data for courses
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getVideos())
      .then(unwrapResult)
      .then((data) => {
        setData(data.video);
        setLoading(false);
      });
  }, [dispatch]);

  return (
    <>
      <title>Videos</title>
      <meta name="keywords" content="tutorials, developers corner" />
      <link rel="canonical" href={window.location.href} />

      <Box component={"main"}>
        <Container maxWidth={"xl"} className="pt-12">
          <div className="text-center">
            <h2 className="text-3xl font-bold mb-4 text-[#4f46e5]">Videos</h2>
            <p className="text-lg mb-8">
              Explore our collection of videos designed to help you build
              stunning user interfaces effortlessly.
            </p>
          </div>
          {/* Mapping through courseData */}
          {loading ? (
            <>
              <Grid
                container
                spacing={3}
                className="my-3"
                justifyContent={"space-between"}
              >
                {Array?.from(new Array(4))?.map((item, index) => {
                  return (
                    <Grid key={index} item xs={12} sm={6} lg={3}>
                      <Skeleton
                        variant="rectangular"
                        className="w-full"
                        height={216}
                      />

                      <div className="p-3 pb-4 mb-6 bg-white rounded-lg shadow-lg">
                        <Skeleton />
                        <Skeleton width="60%" />
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </>
          ) : (
            <Grid
              className="mt-4 mb-5 pb-1"
              container
              spacing={3}
              justifyContent={"stretch"}
            >
              {data?.map((card, cardIndex) => (
                <Grid item key={cardIndex} xs={12} sm={6} lg={3}>
                  <UIKitCard
                    link={`/video/${card?.categoryslug}/${card?.slug}`}
                    image={card?.image}
                    alt={card?.title + " tutorials for beginners"}
                    categoryColor={"black"}
                    category={"Video"}
                    title={card?.title}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Container>
      </Box>
    </>
  );
}

export default Videos;
