// reducers/exampleSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createApiThunk } from "../apiUtils";

const initialState = {};

export const createUser = createApiThunk({
  name: "create-user",
  method: "POST",
  url: "/user/register",
});
export const loginUser = createApiThunk({
  name: "login-user",
  method: "POST",
  url: "/user/login",
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
    },
    setUser(state, action) {
      state.name = action.payload.name;
      state.email = action.payload.email;
    },
    setRole(state, action) {
      state.role = action.payload;
    },
    removeToken(state, action) {
      state.token = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createUser.pending, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { setToken, removeToken, setRole, setUser } = userSlice.actions;

export default userSlice.reducer;
