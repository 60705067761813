import React, { useState } from "react";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  Typography,
  Box,
  Snackbar,
} from "@mui/material";

const QuestionList = ({ data }) => {
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [results, setResults] = useState(null);
  const [error, setError] = useState(false);

  const handleAnswerChange = (questionIndex, answerIndex) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [questionIndex]: answerIndex,
    });
  };

  const handleSubmit = () => {
    // Check if all questions are answered
    const answeredQuestions = Object.keys(selectedAnswers);
    if (answeredQuestions.length !== data.length) {
      setError(true);
      return;
    }

    const newResults = data.map((question, questionIndex) => {
      const selectedAnswerIndex = selectedAnswers[questionIndex];
      const isCorrect =
        selectedAnswerIndex !== undefined &&
        question.answers[selectedAnswerIndex]?.isCorrect;
      return { questionIndex, isCorrect };
    });
    setResults(newResults);
    setError(false); // Reset error state
  };

  const handleReset = () => {
    setSelectedAnswers({});
    setResults(null);
    setError(false);
  };

  const handleCloseError = () => {
    setError(false);
  };
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const { vertical, horizontal, open } = state;

  return (
    <div>
      {data?.map((item, questionIndex) => (
        <div id={item.slug} key={questionIndex} className="mb-4">
          <h1 className="font-bold text-md">
            Question {questionIndex + 1}: {item?.title}
          </h1>
          <p className="font-medium">Answers</p>
          <RadioGroup
            value={
              selectedAnswers[questionIndex] !== undefined
                ? selectedAnswers[questionIndex].toString()
                : ""
            }
            onChange={(e) =>
              handleAnswerChange(questionIndex, parseInt(e.target.value, 10))
            }
          >
            {item?.answers?.map((answer, answerIndex) => (
              <div key={answerIndex} className="flex items-center gap-3">
                <FormControlLabel
                  value={answerIndex.toString()}
                  control={<Radio className="!text-indigo-600" />}
                  label={answer?.content}
                />
              </div>
            ))}
          </RadioGroup>
        </div>
      ))}

      <Box display="flex" gap={2}>
        <Button
          variant="contained"
          className="!bg-indigo-600"
          onClick={handleSubmit}
        >
          Submit
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleReset}>
          Reset
        </Button>
      </Box>
      {results && (
        <div className="mt-4">
          <Typography variant="h6">Results:</Typography>
          {results.map((result) => (
            <Typography key={result.questionIndex}>
              Question {result.questionIndex + 1}:{" "}
              {result.isCorrect ? "Correct" : "Incorrect"}
            </Typography>
          ))}
        </div>
      )}

      <Snackbar
        open={error}
        autoHideDuration={3000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical, horizontal }}
        message="Please answer all questions before submitting."
      />
    </div>
  );
};

export default QuestionList;
