import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import UIKits from "./pages/UIKits";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Footer from "./components/Footer";
import Documentations from "./pages/Documentations";
import Tutorials from "./pages/Tutorials";
import Projects from "./pages/Projects";
import Courses from "./pages/Courses";
import UiKitDetails from "./pages/UiKitDetails";
import TutorialDetails from "./pages/TutorialDetails";
import DocumentationDetails from "./pages/DocumentaionDetail";
import ProjectDetails from "./pages/ProjectDetail";
import Videos from "./pages/Videos";
import VideoDetails from "./pages/VideoDetail";
import Dashboard from "./pages/Dashboard/dashboard";
import SideBar from "./components/Dashboard/Sidebar";
import HeaderComponent from "./components/Header";
import { DbUiKitsCat } from "./pages/Dashboard/DbUiKitsCat";
import { DbUiKits } from "./pages/Dashboard/DbUiKits";
import { DbTutorialCategory } from "./pages/Dashboard/DbTutorialCategory";
import { DbTutorials } from "./pages/Dashboard/DbTutorial";
import { DbDocCategory } from "./pages/Dashboard/DbDocCat";
import { DbDocs } from "./pages/Dashboard/DbDocs";
import { DbStorage } from "./pages/Dashboard/DbStorage";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Refund from "./pages/Refund";
import LoginForm from "./pages/Login";
import RegistrationForm from "./pages/Register";
import PublicRoute from "./utils/PublicRoutes";
import PrivateRoute from "./utils/PrivateRoutes";
import { DbVideos } from "./pages/Dashboard/DbVideo";
import { DbVidCategory } from "./pages/Dashboard/DbVideoCat";
import { DbInterviewCategory } from "./pages/Dashboard/DbIntCat";
import { DbInterview } from "./pages/Dashboard/DbInterview";
import InterViews from "./pages/Interviews";
import IntQuesDetails from "./pages/InterviewQuesDetails";
import { DbProjectCategory } from "./pages/Dashboard/DbProjectCat";
import { DbProjects } from "./pages/Dashboard/DbProject";

const AppRouter = () => {
  const location = useLocation();
  const [sidebar, setSidebar] = useState(false);

  return (
    <div
      className={`flex ${
        location.pathname.includes("/dashboard") ? "flex-row" : "flex-col"
      } overflow-hidden`}
    >
      {location.pathname.includes("/dashboard") ? (
        <SideBar open={sidebar} setSidebar={setSidebar} />
      ) : (
        ""
      )}
      <div className="flex flex-col w-full">
        <HeaderComponent open={sidebar} setSidebar={setSidebar} />
        <Routes>
          <Route
            path="/login"
            element={
              <PublicRoute>
                <LoginForm />
              </PublicRoute>
            }
          />
          <Route
            path="/register"
            element={
              <PublicRoute>
                <RegistrationForm />
              </PublicRoute>
            }
          />

          <Route
            path="/"
            element={
              <PublicRoute>
                <Home />
              </PublicRoute>
            }
          />
          <Route
            path="/ui-kits"
            element={
              <PublicRoute>
                <UIKits />
              </PublicRoute>
            }
          />
          <Route
            path="/ui-kit/:slug"
            element={
              <PublicRoute>
                <UiKitDetails />
              </PublicRoute>
            }
          />
          <Route
            path="/documentations"
            element={
              <PublicRoute>
                <Documentations />
              </PublicRoute>
            }
          />
          <Route
            path="/documentations/:slug"
            element={
              <PublicRoute>
                <Documentations />
              </PublicRoute>
            }
          />
          <Route
            path="/documentation/:slug"
            element={
              <PublicRoute>
                <DocumentationDetails />
              </PublicRoute>
            }
          />
          <Route
            path="/tutorials"
            element={
              <PublicRoute>
                <Tutorials />
              </PublicRoute>
            }
          />
          <Route
            path="/interview-question-categories"
            element={
              <PublicRoute>
                <InterViews />
              </PublicRoute>
            }
          />
          <Route
            path="/interview-questions/:slug"
            element={
              <PublicRoute>
                <IntQuesDetails />
              </PublicRoute>
            }
          />
          <Route
            path="/videos"
            element={
              <PublicRoute>
                <Videos />
              </PublicRoute>
            }
          />
          <Route
            path="/tutorial/:slug/:topic"
            element={
              <PublicRoute>
                <TutorialDetails />
              </PublicRoute>
            }
          />
          <Route
            path="/projects"
            element={
              <PublicRoute>
                <Projects />
              </PublicRoute>
            }
          />
          <Route
            path="/project/:slug"
            element={
              <PublicRoute>
                <ProjectDetails />
              </PublicRoute>
            }
          />
          <Route
            path="/videos"
            element={
              <PublicRoute>
                <Videos />
              </PublicRoute>
            }
          />
          <Route
            path="/video/:catslug/:slug"
            element={
              <PublicRoute>
                <VideoDetails />
              </PublicRoute>
            }
          />
          <Route
            path="/courses"
            element={
              <PublicRoute>
                <Courses />
              </PublicRoute>
            }
          />

          <Route
            path="/privacy-policy"
            element={
              <PublicRoute>
                <Privacy />
              </PublicRoute>
            }
          />
          <Route
            path="/terms-and-conditions"
            element={
              <PublicRoute>
                <Terms />
              </PublicRoute>
            }
          />
          <Route
            path="/refund-policy"
            element={
              <PublicRoute>
                <Refund />
              </PublicRoute>
            }
          />

          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/ui-kits-cat"
            element={
              <PrivateRoute>
                <DbUiKitsCat />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/project-cat"
            element={
              <PrivateRoute>
                <DbProjectCategory />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/projects"
            element={
              <PrivateRoute>
                <DbProjects />
              </PrivateRoute>
            }
          />
          <Route
            path="/projects/:slug"
            element={
              <PublicRoute>
                <ProjectDetails />
              </PublicRoute>
            }
          />
          <Route
            path="/dashboard/ui-kits"
            element={
              <PrivateRoute>
                <DbUiKits />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/tutorials"
            element={
              <PrivateRoute>
                <DbTutorials />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/interviews"
            element={
              <PrivateRoute>
                <DbInterview />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/docs"
            element={
              <PrivateRoute>
                <DbDocs />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/videos"
            element={
              <PrivateRoute>
                <DbVideos />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/storage"
            element={
              <PrivateRoute>
                <DbStorage />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/tutorial-category"
            element={
              <PrivateRoute>
                <DbTutorialCategory />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/video-category"
            element={
              <PrivateRoute>
                <DbVidCategory />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/interview-category"
            element={
              <PrivateRoute>
                <DbInterviewCategory />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/documentation-category"
            element={
              <PrivateRoute>
                <DbDocCategory />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      {!location.pathname.includes("/dashboard") ? <Footer /> : ""}
    </div>
  );
};

export default AppRouter;
