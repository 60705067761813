import { Box, Container } from "@mui/material";

export default function Refund() {
  return (
    <>
      <title>Refund policy</title>
      <meta name="keywords" content="developers corner, developer corner" />
      <link rel="canonical" href={window.location.href} />

      <Box component={"main"}>
        <Container maxWidth={"xl"}>
          <div className="py-12">
            <h1 className="text-4xl font-bold mb-6 text-indigo-600">
              Refund Policy
            </h1>

            <section className="mb-6">
              <p>Thank you for shopping at Developer's Corner.</p>
              <p>
                If you are not entirely satisfied with your purchase, we're here
                to help.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-4 text-indigo-600">
                Returns
              </h2>
              <p>
                You have 30 calendar days to return an item from the date you
                received it. To be eligible for a return, your item must be
                unused and in the same condition that you received it. Your item
                must be in the original packaging. Your item needs to have the
                receipt or proof of purchase.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-4 text-indigo-600">
                Refunds
              </h2>
              <p>
                Once we receive your item, we will inspect it and notify you
                that we have received your returned item. We will immediately
                notify you of the status of your refund after inspecting the
                item.
              </p>
              <p>
                If your return is approved, we will initiate a refund to your
                credit card (or original method of payment). You will receive
                the credit within a certain amount of days, depending on your
                card issuer's policies.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-4 text-indigo-600">
                Shipping
              </h2>
              <p>
                You will be responsible for paying for your own shipping costs
                for returning your item. Shipping costs are non-refundable. If
                you receive a refund, the cost of return shipping will be
                deducted from your refund.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold mb-4 text-indigo-600">
                Contact Us
              </h2>
              <p>
                If you have any questions on how to return your item to us,
                contact us at{" "}
                <a
                  href="mailto:support@developerscorner.in"
                  className="text-indigo-600 underline"
                >
                  support@developerscorner.in
                </a>
                .
              </p>
            </section>
          </div>
        </Container>
      </Box>
    </>
  );
}
