import { Box, Container, Grid } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import UIKitCard from "../components/UiKits/Card";
import "react-multi-carousel/lib/styles.css";
import { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { fetchCbCategories } from "../redux/slices/codeBlockSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useLocation } from "react-router-dom";

const CACHE_KEY = "uiKitsCache";
const CACHE_DURATION = 1000 * 60 * 10; // 10 minutes

function UiKits({ editMode }) {
  const location = useLocation();
  const [uiKits, setUiKits] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const isDashboard = useMemo(
    () => location.pathname.includes("/dashboard"),
    [location.pathname]
  );

  const loadFromCache = useCallback(() => {
    const cachedData = localStorage.getItem(CACHE_KEY);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      const isCacheValid = Date.now() - timestamp < CACHE_DURATION;
      if (isCacheValid) {
        setUiKits(data);
        setLoading(false);
        return true;
      }
    }
    return false;
  }, []);

  useEffect(() => {
    if (!loadFromCache()) {
      const fetchCategories = async () => {
        try {
          const result = await dispatch(fetchCbCategories()).then(unwrapResult);
          setUiKits(result);
          localStorage.setItem(
            CACHE_KEY,
            JSON.stringify({ data: result, timestamp: Date.now() })
          );
        } catch (error) {
          console.error("Failed to fetch categories:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchCategories();
    }
  }, [dispatch, loadFromCache]);

  const renderSkeletons = useCallback(() => {
    return (
      <>
        <Grid
          container
          spacing={3}
          className="my-5 pt-5"
          justifyContent={"stretch"}
        >
          <Grid item xs={12}>
            <Skeleton
              variant="rectangular"
              className="w-full mb-3"
              height={216}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          className="my-5"
          justifyContent="space-between"
        >
          {Array.from(new Array(4)).map((_, index) => (
            <Grid key={index} item xs={12} sm={6} lg={3}>
              <div className="p-3 pb-4 mb-6 bg-white rounded-lg shadow-lg">
                <Skeleton
                  variant="rectangular"
                  className="w-full"
                  height={216}
                />
                <Skeleton />
                <Skeleton width="60%" />
              </div>
            </Grid>
          ))}
        </Grid>
      </>
    );
  }, []);

  return (
    <>
      <title>Ui Kits</title>
      <meta name="keywords" content="ui kits, developers corner" />
      <link rel="canonical" href="https://www.developerscorner.in/ui-kits" />

      <Box
        component="main"
        className={`flex-1 flex flex-col ${
          isDashboard ? "overflow-y-auto h-[100vh]" : ""
        }`}
      >
        <Container maxWidth="xl" className="pt-12">
          <div
            className={`flex ${
              isDashboard ? "justify-between" : "justify-center"
            } items-center`}
          >
            <div className={`${isDashboard ? "" : "text-center"}`}>
              <h2 className="text-3xl font-bold mb-4 text-[#4f46e5]">
                UI Kits
              </h2>
              <p className="text-lg mb-8">
                Explore our collection of UI kits designed to help you build
                stunning user interfaces effortlessly.
              </p>
            </div>
          </div>

          {loading
            ? renderSkeletons()
            : uiKits?.map((category, index) => (
                <div key={index} className="tag-container my-5">
                  <div className="flex gap-3 items-center mb-3">
                    <h3 className="text-xl font-bold">{category.name}</h3>
                  </div>

                  <Grid container spacing={3} justifyContent="stretch">
                    {category?.components?.map((card, cardIndex) => (
                      <Grid item key={cardIndex} xs={12} sm={6} lg={4}>
                        {card ? (
                          <UIKitCard
                            link={`/ui-kit/${card?.slug}`}
                            image={card?.image}
                            alt={card?.name}
                            categoryColor="black"
                            category={category?.type}
                            date={card?.date}
                            title={card?.name}
                          />
                        ) : (
                          <Skeleton
                            variant="rectangular"
                            width={210}
                            height={118}
                          />
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </div>
              ))}
        </Container>
      </Box>
    </>
  );
}

export default UiKits;
