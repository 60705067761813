import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { removeToken } from "../../redux/slices/userSlice";

export default function SideBar({ open, setSidebar }) {
  const [openItems, setOpenItems] = useState({});
  const user = useSelector((state) => state.user);
  const location = useLocation();

  const navs = [
    {
      id: 0,
      name: "Dashboard",
      link: "/dashboard",
      subLinks: [],
      icon: <img src="/dash.svg" className="w-6 h-6 mr-3" alt="dashboard" />,
    },
    {
      id: 1,
      name: "Ui Categories",
      link: "",
      subLinks: [{ id: 0, name: "View", link: "/dashboard/ui-kits-cat/" }],
      icon: <img src="/cat.svg" className="w-6 h-6 mr-3" alt="category" />,
    },
    {
      id: 2,
      name: "Ui Kits",
      link: "",
      icon: <img src="/ui.svg" className="w-5 h-5 mr-3" alt="ui" />,
      subLinks: [{ id: 0, name: "View", link: "/dashboard/ui-kits/" }],
    },

    {
      id: 3,
      name: "Tutorial Category",
      link: "",
      icon: <img src="/cat.svg" className="w-6 h-6 mr-3" alt="category" />,
      subLinks: [{ id: 0, name: "View", link: "/dashboard/tutorial-category" }],
    },
    {
      id: 4,
      name: "Tutorial ",
      link: "",
      icon: <img src="/tutorial.svg" className="w-5 h-5 mr-3" alt="tutorial" />,
      subLinks: [{ id: 0, name: "View", link: "/dashboard/tutorials" }],
    },
    {
      id: 5,
      name: "Doc Category ",
      link: "",
      icon: <img src="/cat.svg" className="w-6 h-6 mr-3" alt="category" />,
      subLinks: [
        { id: 0, name: "View", link: "/dashboard/documentation-category" },
      ],
    },
    {
      id: 6,
      name: "Documentation ",
      link: "",
      icon: <img src="/document.svg" className="w-5 h-5 mr-3" alt="document" />,
      subLinks: [{ id: 0, name: "View", link: "/dashboard/docs" }],
    },
    {
      id: 7,
      name: "Video Category",
      link: "",
      icon: <img src="/cat.svg" className="w-6 h-6 mr-3" alt="category" />,
      subLinks: [{ id: 0, name: "View", link: "/dashboard/video-category" }],
    },
    {
      id: 8,
      name: "Videos",
      link: "",
      icon: <img src="/video.svg" className="w-6 h-6 mr-3" alt="videos" />,
      subLinks: [{ id: 0, name: "View", link: "/dashboard/videos" }],
    },
    {
      id: 9,
      name: "IV Category",
      link: "",
      icon: <img src="/cat.svg" className="w-6 h-6 mr-3" alt="category" />,
      subLinks: [
        { id: 0, name: "View", link: "/dashboard/interview-category" },
      ],
    },
    {
      id: 10,
      name: "IV's",
      link: "",
      icon: (
        <img src="/interview.svg" className="w-5 h-5 mr-3" alt="interview" />
      ),
      subLinks: [{ id: 0, name: "View", link: "/dashboard/interviews" }],
    },
    {
      id: 11,
      name: "Project Category",
      link: "",
      icon: <img src="/cat.svg" className="w-6 h-6 mr-3" alt="category" />,
      subLinks: [{ id: 0, name: "View", link: "/dashboard/project-cat" }],
    },
    {
      id: 12,
      name: "Project's",
      link: "",
      icon: (
        <img src="/interview.svg" className="w-5 h-5 mr-3" alt="interview" />
      ),
      subLinks: [{ id: 0, name: "View", link: "/dashboard/projects" }],
    },
    {
      id: 13,
      name: "Media Storage",
      link: "",
      icon: <img src="/media.svg" className="w-5 h-5 mr-3" alt="media" />,
      subLinks: [{ id: 0, name: "View", link: "/dashboard/storage" }],
    },
    {
      id: 14,
      name: "Logout",
      link: "",
      icon: <img src="/out.svg" className="w-5 h-5 mr-3" alt="logout" />,
    },
  ];
  // Create the pathnameToIndexMap from the navs array
  const pathnameToIndexMap = {};
  navs.forEach((nav) => {
    if (nav.link) {
      pathnameToIndexMap[nav.link] = nav.id;
    }
    nav?.subLinks?.forEach((subLink) => {
      pathnameToIndexMap[subLink.link] = nav.id;
    });
  });
  console.log(pathnameToIndexMap);
  useEffect(() => {
    const currentPath = location.pathname;
    const indexToOpen = pathnameToIndexMap[currentPath];

    if (indexToOpen !== undefined) {
      setOpenItems({ [indexToOpen]: true });
    }
  }, [location.pathname]);
  console.log(openItems);
  const handleToggle = (id) => {
    setOpenItems((prevState) => {
      const newState = Object.keys(prevState).reduce((acc, key) => {
        acc[key] = false; // Set all items to false
        return acc;
      }, {});

      return {
        ...newState,
        [id]: !prevState[id], // Toggle the clicked item
      };
    });
  };
  const dispatch = useDispatch();
  console.log(Object.keys(openItems)[0]);

  return (
    <div
      style={{ marginTop: "72px" }}
      className={`w-96 z-10 bg-gray-800 sidebar text-gray-100 flex flex-col fixed md:relative h-[100vh] ${
        open ? "active" : ""
      }`}
    >
      <div className="flex items-center p-4 pt-5  bg-indigo-600">
        <div className="ml-4">
          <p className="text-sm font-medium text-white">
            {user?.name ? user?.name : "Admin User"}
          </p>
          <p className="text-xs text-white">
            {user?.email ? user?.email : "admin@gmail.com"}
          </p>
        </div>
      </div>
      <nav className="flex-1 px-4 py-4 pb-[100px] space-y-2 overflow-y-auto bg-white shadow-lg">
        {navs?.map((item, index) => {
          console.log(Object?.keys(openItems)?.[0] === index.toString());
          return (
            <div key={item.id}>
              <div
                className={`${
                  Object?.keys(openItems)?.[0] === index.toString()
                    ? "bg-indigo-300 "
                    : "bg-blue-300"
                } flex items-center justify-between px-4 py-2 text-black hover:bg-indigo-300  rounded-sm cursor-pointer`}
                onClick={() => handleToggle(item.id)}
              >
                <Link
                  to={item.link ? item.link : "#"}
                  className="flex items-center"
                  onClick={() => {
                    if (item.name === "Logout") {
                      dispatch(removeToken());
                      setSidebar(false);
                    } else if (item.name === "Dashboard") {
                      setSidebar(false);
                    }
                  }}
                >
                  {item.icon}
                  {item.name}
                </Link>
                {item?.subLinks?.length > 0 && (
                  <svg
                    className={`w-4 h-4 transform transition-transform ${
                      openItems[item.id] ? "rotate-180" : ""
                    }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                )}
              </div>
              {openItems[item.id] && (
                <div className="pl-8 space-y-2 mt-1">
                  {item?.subLinks?.map((subLink) => (
                    <Link
                      key={subLink.id}
                      to={subLink.link}
                      onClick={() => setSidebar(false)}
                      className={`${
                        Object?.keys(openItems)?.[0] === index.toString()
                          ? "bg-indigo-300 "
                          : "bg-blue-300"
                      } flex items-center px-4 py-2 text-black hover:bg-indigo-300  rounded-sm`}
                    >
                      {subLink.name}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          );
        })}
      </nav>
    </div>
  );
}
