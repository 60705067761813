import { Box, Container } from "@mui/material";

export default function Dashboard() {
  return (
    <Box component={"main"}>
      <Container maxWidth={"xl"}>
        <div className="flex-1 flex flex-col ">
          <main className="flex-1  overflow-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
              <div className="bg-white p-4 rounded-lg shadow-md">
                <h3 className="text-lg font-semibold text-gray-900">Users</h3>
                <p className="mt-2 text-gray-600">
                  Manage users and permissions.
                </p>
              </div>
              <div className="bg-white p-4 rounded-lg shadow-md">
                <h3 className="text-lg font-semibold text-gray-900">Reports</h3>
                <p className="mt-2 text-gray-600">
                  View system reports and statistics.
                </p>
              </div>
              <div className="bg-white p-4 rounded-lg shadow-md">
                <h3 className="text-lg font-semibold text-gray-900">
                  Settings
                </h3>
                <p className="mt-2 text-gray-600">Configure system settings.</p>
              </div>
            </div>
          </main>
        </div>
      </Container>
    </Box>
  );
}
