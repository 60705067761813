import { Box, Container, Typography } from "@mui/material";
import { useState } from "react";
import * as React from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useDispatch } from "react-redux";
import { fetchD, fetchDbyCatSlug } from "../redux/slices/documentationSlice";
import { Link, useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import hljs from "highlight.js";
import "highlight.js/styles/default.css";
import "../App.css";

export default function DocumentationDetails() {
  const dispatch = useDispatch();
  const params = useParams();
  const [doc, setDoc] = useState();
  const [listdata, setlist] = useState([]);
  const [topic, setTopic] = useState("");
  React.useEffect(() => {
    dispatch(fetchD({ queryParams: params.slug }))
      .then(unwrapResult)
      .then((data) => {
        if (data.status) {
          setDoc(data.doc);
          setTimeout(() => {
            hljs.highlightAll();
          }, 400);
        }
      });
  }, [params.slug]);
  React.useEffect(() => {
    if (topic !== "") {
      return;
    }
    dispatch(fetchDbyCatSlug({ queryParams: doc?.categorySlug }))
      .then(unwrapResult)
      .then((data) => {
        if (data.status) {
          setlist(data.doc);
          setTimeout(() => {
            hljs.highlightAll();
          }, 400);
        }
      });
  }, [topic, doc?.categorySlug]);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 280 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Typography
        component={"div"}
        fontSize={22}
        fontWeight={"bold"}
        className="ps-0 p-4 text-[#4f46e5]"
      >
        {doc?.category} <br /> Documentations
      </Typography>
      <Divider />
      <List>
        {listdata?.map((text, index) => (
          <ListItem key={text.title} disablePadding>
            <ListItemButton
              className={`${
                params.slug === text.slug ? "!bg-[#0000000a]" : ""
              }`}
            >
              <ListItemText>
                <Link
                  onClick={() => setTopic(text?.slug)}
                  to={`/documentation/${text.slug}`}
                >
                  {text.title}
                </Link>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const schemaOrgJSONLD = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Developer's Corner",
    url: window.location.href,
    logo: "https://www.developerscorner.in/logo.png",
    description: doc?.title,
    sameAs: [
      "https://www.linkedin.com/in/navdeep-dahiya-719016186/",
      "https://instagram.com/heartblack1676",
      "https://www.youtube.com/@developerscorner",
    ],
  };
  return (
    <>
      <title>{doc?.title}</title>
      <meta name="keywords" content={doc?.keywords} />
      <link rel="canonical" href={window.location.href}></link>

      <Box overflow={"hidden"} component={"main"} className="pt-12">
        <Container maxWidth={"xl"}>
          <Box sx={{ display: "flex" }}>
            <Drawer
              style={{ position: "relative" }}
              anchor={"left"}
              onClose={toggleDrawer("left", false)}
              variant="permanent"
              sx={{
                display: { xs: "none", lg: "block", zIndex: 0 },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: 285,
                  position: "relative",
                },
              }}
              open
            >
              {list("Open SideBar")}
            </Drawer>
            <Box width={"100%"} className="p-0 lg:p-5 lg:pt-0">
              <div
                dangerouslySetInnerHTML={{
                  __html: doc?.content ? doc?.content : "",
                }}
              ></div>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}
