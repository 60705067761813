import {
  Alert,
  Container,
  Drawer,
  Snackbar,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Box,
} from "@mui/material";

import TableComponent from "../../components/Table/TableComponent";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import JoditEditor from "jodit-react";

import ImageUpload from "../../components/Upload";

import {
  createdoc,
  deleteD,
  fetchD,
  fetchDbyId,
  updateD,
} from "../../redux/slices/documentationSlice";
import { fetchDC } from "../../redux/slices/docCatSlice";

const formSchema = Yup.object().shape({
  title: Yup.string().trim().required("Title is required"),
  description: Yup.string().trim().required("Description is required"),
  category: Yup.string().trim().required("Category is Required"),
  type: Yup.string().trim().required("Type is required"),
  content: Yup.string().trim().required("Content is required"),
  keywords: Yup.string().trim().required("Keywords are required"),
  image: Yup.string().trim().required("Image is Required"),
  image_url: Yup.string().trim(),
});
export const DbDocs = () => {
  const [uiKits, setUiKits] = useState([{}]);
  const [uiKitCategories, setUiKitCategories] = useState([{}]);
  const [updateCodeBlocks, setUpdateCodeBlocks] = useState(0);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [snack, setSnack] = useState(false);
  const [msg, setMsg] = useState("");
  const [openCodeBlock, setOpenCodeBlock] = useState(false);
  const [edit, setEditMode] = useState(false);
  const editor = useRef(null);

  useEffect(() => {
    dispatch(fetchD())
      .then(unwrapResult)
      .then((result) => {
        // Function to filter out excluded keys from an object
        const filterObject = (obj) => {
          return Object.keys(obj)
            .filter((key) => !excludeKeys.includes(key))
            .reduce((acc, key) => {
              acc[key] = obj[key];
              return acc;
            }, {});
        };

        // Map the data using the filterObject function
        setUiKits(result?.doc?.map(filterObject));
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        console.error(error);
      });
    dispatch(fetchDC())
      .then(unwrapResult)
      .then((result) => {
        // Map the data using the filterObject function

        setUiKitCategories(result.allDoccat);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [updateCodeBlocks]);
  // Specify keys to exclude
  const excludeKeys = [
    "image_url",
    "slug",
    "__v",
    "updatedAt",
    "content",
    "image",
    "code",
    "author",
    "keywords",
    "categorySlug",
    "slug",
    "description",
  ];
  const headers = [
    { id: "_id", label: "_ID" },
    { id: "title", label: "Title" },
    { id: "category", label: "Category" },

    { id: "type", label: "Type" },

    { id: "createdAt", label: "Created At" },
  ];
  const onDelete = async (id) => {
    await dispatch(
      deleteD({
        queryParams: id,
      })
    )
      .then(unwrapResult)
      .then(() => {
        setSnack(true);
        setMsg("Documentation Deleted!");
        setUpdateCodeBlocks(updateCodeBlocks + 1);
      });
  };
  const [editId, setEditId] = useState();
  const getDById = async (editId) => {
    await dispatch(fetchDbyId({ queryParams: editId }))
      .then(unwrapResult)
      .then((data) => {
        setData(data.updatedData);
      });
  };
  const onEdit = (id) => {
    getDById(id);

    setEditMode(true);
    setOpenCodeBlock(true);
    setEditId(id);
  };
  return (
    <Box component={"main"}>
      <Container maxWidth={"xl"}>
        <Snackbar
          open={snack}
          onClose={() => setSnack(false)}
          autoHideDuration={5000}
        >
          <Alert
            onClose={() => setSnack(false)}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {msg}
          </Alert>
        </Snackbar>
        {/* form for code block  */}

        <Drawer
          open={openCodeBlock}
          anchor="right"
          onClose={() => setOpenCodeBlock(false)}
        >
          <div className="flex  ">
            <div className="w-full md:w-[600px] p-4 md:p-8  rounded-lg ">
              <div className="flex mb-6  justify-between items-center">
                <h2 className="text-md md:text-2xl font-bold text-center">
                  {edit ? "Edit" : "Create New"} Docs
                </h2>
                <div
                  onClick={() => setOpenCodeBlock(false)}
                  className="w-[15px] h-[15px] ms-3"
                >
                  <svg
                    fill="#000000"
                    height="15px"
                    width="15px"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 490 490"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <polygon points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490 489.292,457.678 277.331,245.004 489.292,32.337 "></polygon>{" "}
                    </g>
                  </svg>
                </div>
              </div>

              <Formik
                initialValues={{
                  title: data?.title || "",
                  description: data?.description || "",
                  category: data?.category || "",
                  type: data?.type || "",
                  content: data?.content || "",
                  keywords: data?.keywords || "",
                  image: data?.image || "",
                }}
                validationSchema={formSchema}
                enableReinitialize={edit}
                onSubmit={async (values) => {
                  console.log(values);

                  if (edit) {
                    await dispatch(
                      updateD({ data: values, queryParams: editId })
                    ).then((data) => {
                      setSnack(true);
                      setMsg("Documentation Updated!");
                      setOpenCodeBlock(false);
                      setUpdateCodeBlocks(updateCodeBlocks + 1);
                    });
                  } else {
                    await dispatch(createdoc({ data: values })).then((data) => {
                      setSnack(true);
                      setMsg("Documentation Added!");
                      setOpenCodeBlock(false);
                      setUpdateCodeBlocks(updateCodeBlocks + 1);
                    });
                  }
                }}
              >
                {({ errors, touched, setFieldValue }) => (
                  <Form>
                    <div className="mb-4">
                      <label
                        htmlFor="title"
                        className="block mb-2 text-sm font-medium text-gray-700"
                      >
                        Title
                      </label>
                      <Field
                        name="title"
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                      <ErrorMessage
                        name="title"
                        component="div"
                        className="mt-2 text-sm text-red-600"
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="description"
                        className="block mb-2 text-sm font-medium text-gray-700"
                      >
                        Description
                      </label>
                      <Field
                        name="description"
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="mt-2 text-sm text-red-600"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="type"
                        className="block mb-2 text-sm font-medium text-gray-700"
                      >
                        Type
                      </label>
                      <Field
                        name="type"
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                      <ErrorMessage
                        name="type"
                        component="div"
                        className="mt-2 text-sm text-red-600"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="content"
                        className="block mb-2 text-sm font-medium text-gray-700"
                      >
                        Content
                      </label>
                      <JoditEditor
                        value={data?.content || ""}
                        tabIndex={1}
                        onBlur={(newContent) =>
                          setFieldValue("content", newContent)
                        }
                        onChange={(newContent) => {}}
                        className="border h-[300px] border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                      <ErrorMessage
                        name="content"
                        component="div"
                        className="mt-2 text-sm text-red-600"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="keywords"
                        className="block mb-2 text-sm font-medium text-gray-700"
                      >
                        Keywords
                      </label>
                      <Field
                        name="keywords"
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                      <ErrorMessage
                        name="keywords"
                        component="div"
                        className="mt-2 text-sm text-red-600"
                      />
                    </div>

                    <div className="mb-4">
                      <FormControl fullWidth>
                        <InputLabel htmlFor="category">Category</InputLabel>
                        <Field
                          name="category"
                          as={Select}
                          label="Category"
                          onChange={(event) =>
                            setFieldValue("category", event.target.value)
                          }
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {uiKitCategories?.map((item) => (
                            <MenuItem key={item._id} value={item.title}>
                              {item.title}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormControl>
                      <ErrorMessage
                        name="category"
                        component="div"
                        className="mt-2 text-sm text-red-600"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="image"
                        className="block mb-2 text-sm font-medium text-gray-700"
                      >
                        Image
                      </label>
                      <ImageUpload
                        id="icon-button-file"
                        name="image"
                        setFieldValue={setFieldValue}
                        setSnack={setSnack}
                        setMsg={setMsg}
                        imgSrc={data?.image}
                      />

                      <ErrorMessage
                        name="image"
                        component="div"
                        className="mt-2 text-sm text-red-600"
                      />
                    </div>

                    <div className="flex items-center justify-start">
                      <button
                        type="submit"
                        className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      >
                        {edit ? "Update" : "Add"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Drawer>
        <div className="flex items-center justify-between">
          <h3 className="text-lg md:text-3xl font-bold my-5">List Docs </h3>
          <h6
            onClick={() => {
              setData();
              setEditMode(false);
              setOpenCodeBlock(true);
            }}
            className="font-bold rounded-md cp  text-white"
          >
            <img src="/add.svg" width={30} alt="" />
          </h6>
        </div>
        <TableComponent
          initialData={uiKits || []}
          headers={headers}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </Container>
    </Box>
  );
};
