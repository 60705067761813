import "./App.css";
import AppRouter from "./AppRouter";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import { useDispatch } from "react-redux";
import { axiosInstance } from "./redux/apiUtils";
import { useEffect } from "react";
import store from "./redux/store";

function App() {
  // Add a request interceptor
  const dispatch = useDispatch();
  useEffect(() => {
    axiosInstance.interceptors.request.use(
      (config) => {
        console.log(store.getState().user?.token);
        if (store.getState().user?.token) {
          config.headers.Authorization = `Bearer ${
            store.getState().user?.token
          }`;
        } else {
          config.headers.Authorization = `Bearer ${""}`;
        }
        return config;
      },
      (error) => {
        return error;
      }
    );
  }, [dispatch]);
  return (
    <BrowserRouter>
      <ScrollToTop />
      <AppRouter />
    </BrowserRouter>
  );
}

export default App;
