import { Box, Container, Typography } from "@mui/material";
import { useState } from "react";
import * as React from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
// Using ES6 import syntax

import "../App.css";
import { getinterviewBySlug } from "../redux/slices/interviewSlice";
import QuestionList from "./QuestionList";

export default function IntQuesDetails() {
  const [data, setData] = useState();
  const params = useParams();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getinterviewBySlug({ queryParams: `${params.slug}` }))
      .then(unwrapResult)
      .then((data) => {
        setData(data);
      });
  }, [params.topic]);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Typography
        component={"div"}
        fontSize={22}
        fontWeight={"bold"}
        className="ps-0 p-4 pt-1 text-[#4f46e5]"
      >
        List
      </Typography>
      <Divider />
      <List>
        {data?.map((text, index) => (
          <ListItem key={text._id} disablePadding>
            <ListItemButton>
              <ListItemText>
                <a href={`#${text?.slug}`}>{text.title}</a>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <React.Fragment>
      <title>{data?.getATutData?.topicName}</title>
      <meta name="keywords" content={data?.getATutData?.keywords} />
      <link rel="canonical" href={window.location.href} />

      <Box overflow={"hidden"} component={"main"} className="pt-12">
        <Container maxWidth={"xl"}>
          <Box sx={{ display: "flex" }}>
            <Box className="h-[500px] sticky top-0">
              <Drawer
                style={{ position: "sticky" }}
                anchor={"left"}
                onClose={toggleDrawer("left", false)}
                variant="persistent"
                sx={{
                  marginBottom: 6,
                  display: { xs: "none", lg: "block", zIndex: 0 },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: 255,
                    position: "sticky",
                  },
                }}
                open
              >
                {list("Open SideBar")}
              </Drawer>
            </Box>

            <Box width={"100%"} className="mb-10 p-0 sm:p-3 sm:pt-0">
              <h1 className="text-3xl font-bold mb-4 text-[#4f46e5]">
                {params.slug.toUpperCase() + " Questions"}
              </h1>
              <QuestionList data={data}></QuestionList>
            </Box>
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  );
}
