import {
  Box,
  Button,
  Container,
  ImageList,
  ImageListItem,
  Modal,
  Typography,
} from "@mui/material";

import * as React from "react";
import { useDispatch } from "react-redux";
import { fetchPbyId } from "../redux/slices/projectSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useParams } from "react-router-dom";
import Moment from "react-moment";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

export default function ProjectDetails() {
  const [pageData, setPageData] = React.useState("");
  const params = useParams();
  const [orderId, setOrderId] = React.useState(null);
  const [message, setMessage] = React.useState("");

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchPbyId({ queryParams: params.slug }))
      .then(unwrapResult)
      .then((data) => {
        if (data?.status) {
          setPageData(data?.data);
        }
      });
  }, [dispatch, params.slug]);

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const initialOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    "enable-funding": "venmo",
    "disable-funding": "",
    country: "US",
    currency: "USD",
    "data-page-type": "product-details",
    components: "buttons",
    "data-sdk-integration-source": "developer-studio",
  };

  return (
    <Box component={"main"} className="pt-12">
      <Container maxWidth={"xl"} className="pb-12">
        <div>
          <h1 className="text-4xl font-bold mb-4">{pageData?.title}</h1>
          <p className="text-sm text-gray-600 mb-6">
            Category:{" "}
            <span className="font-semibold">{pageData?.category}</span>
          </p>
          <div className="mb-6">
            <ImageList variant="masonry" cols={3} gap={8}>
              {pageData?.images?.map((item) => (
                <ImageListItem key={item}>
                  <img
                    srcSet={`${item}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    src={`${item}?w=248&fit=crop&auto=format`}
                    alt={item}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </div>
          <div className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Description</h2>
            <p>{pageData?.desc}</p>
          </div>
          <div className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Tech Stack</h2>
            <p className="text-lg">{pageData?.techStack}</p>
          </div>
          <div className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Content</h2>
            <div className="prose">
              <div
                dangerouslySetInnerHTML={{ __html: pageData?.content }}
              ></div>
            </div>
          </div>
          <div className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Links</h2>
            <ul className="list-disc pl-5 text-lg">
              {pageData?.links?.map((item) => (
                <li key={item?.url}>
                  {item?.name} {": "}
                  <a href={item?.url} className="text-blue-500 hover:underline">
                    {item?.url}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Price</h2>
            <p className="text-lg font-bold text-green-400">
              {pageData?.priceAfterDiscount}
            </p>
            <p className="text-lg text-gray-600 line-through">
              {pageData?.price}
            </p>
          </div>
          <div className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Author</h2>
            <p className="text-lg">Developer's Corner</p>
          </div>
          <div className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Updated At</h2>
            <p className="text-lg">
              <Moment format="DD MMM, YYYY : hh:mm:a">
                {pageData?.updatedAt}
              </Moment>
            </p>
          </div>

          <div className="flex justify-center">
            <div>
              <Button variant="contained" color="primary" onClick={handleOpen}>
                Purchase Project
              </Button>

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="paypal-modal-title"
                aria-describedby="paypal-modal-description"
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 4,
                    textAlign: "center",
                    overflow: "scroll",
                  }}
                >
                  <Typography
                    id="paypal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Complete Your Purchase
                  </Typography>
                  <Typography
                    id="paypal-modal-description"
                    sx={{ mt: 2, mb: 2 }}
                  >
                    Click the button below to proceed with PayPal.
                  </Typography>
                  <PayPalScriptProvider options={initialOptions}>
                    <PayPalButtons
                      style={{
                        shape: "rect",
                        layout: "vertical",
                        color: "gold",
                        label: "paypal",
                      }}
                      createOrder={async () => {
                        try {
                          const response = await fetch(
                            "https://api.developerscorner.in/api/orders",
                            {
                              method: "POST",
                              headers: {
                                "Content-Type": "application/json",
                              },
                              body: JSON.stringify({
                                projectId: "66b5174c1a747f4302886424", // Replace with actual projectId
                                userId: "668c38289f98e8466e09236d", // Replace with actual userId
                                paymentMethod: "PayPal",
                              }),
                            }
                          );

                          const orderData = await response.json();

                          if (orderData.paypalOrderId) {
                            setOrderId(orderData.paypalOrderId);
                            return orderData.paypalOrderId;
                          } else {
                            throw new Error("Failed to create PayPal order.");
                          }
                        } catch (error) {
                          console.error(error);
                          setMessage(
                            `Could not initiate PayPal Checkout...${error.message}`
                          );
                        }
                      }}
                      onApprove={async (data, actions) => {
                        try {
                          const response = await fetch(
                            `https://api.developerscorner.in/api/orders/${data.orderID}/capture`,
                            {
                              method: "POST",
                              headers: {
                                "Content-Type": "application/json",
                              },
                            }
                          );

                          const orderData = await response.json();

                          if (orderData.order.paymentStatus === "Paid") {
                            setMessage(
                              `Transaction completed successfully. Order ID: ${data.orderID}`
                            );
                            handleClose(); // Close the modal after successful payment
                          } else {
                            throw new Error("Payment failed.");
                          }
                        } catch (error) {
                          console.error(error);
                          setMessage(
                            `Sorry, your transaction could not be processed...${error.message}`
                          );
                        }
                      }}
                    />
                  </PayPalScriptProvider>
                  <Typography variant="body2" color="error">
                    {message}
                  </Typography>
                </Box>
              </Modal>
            </div>
          </div>
        </div>
      </Container>
    </Box>
  );
}
