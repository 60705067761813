import { Box, Container, Grid } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const HeroComponent = ({ toggleDrawer }) => {
  return (
    <Box>
      <Container maxWidth={"xl"} className="relative bg-white overflow-hidden">
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6} className="mx-auto">
            <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20  lg:w-full lg:pb-28 xl:pb-32">
              <main className="mt-10 mx-auto  sm:mt-12  md:mt-16 lg:mt-20 xl:mt-28">
                <div className="sm:text-center lg:text-left">
                  <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                    <span className="block xl:inline">
                      Welcome To <br />
                    </span>
                    <span className="block text-indigo-600 xl:inline">
                      Developer's Corner
                    </span>
                  </h1>
                  <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                    At Developer's Corner, we're dedicated to empowering
                    software developers with the tools and knowledge they need
                    to succeed. Whether you're a seasoned pro or just starting
                    your coding journey, you'll find resources tailored to
                    enhance your skills and accelerate your projects.
                  </p>
                  <div className="mt-5 sm:mt-8 xl:flex xs:flex-col xl:flex-row sm:justify-center lg:justify-start">
                    <div className="rounded-md shadow">
                      <Link
                        to={"/ui-kits"}
                        className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                      >
                        Discover Our Ready-Made UI Kits
                      </Link>
                    </div>
                    <div className="mt-3 sm:mt-3 xl:mt-0 xl:ml-3">
                      <Link
                        to={"/tutorials"}
                        className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10"
                      >
                        Explore Our Tutorials
                      </Link>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            className="xl:absolute lg:inset-y-0 lg:right-0 "
          >
            <img
              className="h-56 w-full object-cover sm:h-72 md:h-96 lg:h-full"
              src="/hero.webp"
              alt="hero"
              width="600"
              height="400"
              loading="lazy"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default HeroComponent;
