import {
  Alert,
  Container,
  Drawer,
  Snackbar,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Box,
} from "@mui/material";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css"; //Example style, you can use another
import TableComponent from "../../components/Table/TableComponent";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import JoditEditor from "jodit-react";
import {
  createCb,
  deleteCb,
  fetchCb,
  fetchCbById,
  fetchCbCategories,
  updateCb,
} from "../../redux/slices/codeBlockSlice";
import ImageUpload from "../../components/Upload";
import Editor from "react-simple-code-editor";

const formSchema = Yup.object().shape({
  name: Yup.string().trim().required("Name is required"),
  code: Yup.string().trim().required("Code is required"),
  image: Yup.string().trim(),

  language: Yup.string().required("Langauage is Required"),
  category: Yup.string().required("Category is required"),
  desc: Yup.string().trim().required("Description is required"),
  keywords: Yup.string().trim().required("Keywords are required"),
  componentName: Yup.string().trim(),
});
export const DbUiKits = () => {
  const [uiKits, setUiKits] = useState([{}]);
  const [uiKitCategories, setUiKitCategories] = useState([{}]);
  const [updateCodeBlocks, setUpdateCodeBlocks] = useState(0);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [snack, setSnack] = useState(false);
  const [msg, setMsg] = useState("");
  const [openCodeBlock, setOpenCodeBlock] = useState(false);
  const [code, setCode] = useState("");
  const [edit, setEditMode] = useState(false);
  const editor = useRef(null);

  useEffect(() => {
    dispatch(fetchCb())
      .then(unwrapResult)
      .then((result) => {
        // Function to filter out excluded keys from an object
        const filterObject = (obj) => {
          return Object.keys(obj)
            .filter((key) => !excludeKeys.includes(key))
            .reduce((acc, key) => {
              acc[key] = obj[key];
              return acc;
            }, {});
        };

        // Map the data using the filterObject function
        setUiKits(result?.map(filterObject));
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        console.error(error);
      });
    dispatch(fetchCbCategories())
      .then(unwrapResult)
      .then((result) => {
        // Map the data using the filterObject function
        setUiKitCategories(result);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [updateCodeBlocks]);
  // Specify keys to exclude
  const excludeKeys = [
    "image_url",
    "slug",
    "__v",
    "updatedAt",
    "componentCount",
    "image",
    "code",
    "category",
    "language",
    "keywords",
    "desc",
    "componentName",
  ];
  const headers = [
    { id: "_id", label: "_ID" },
    { id: "name", label: "Name" },

    { id: "createdAt", label: "Created At" },
  ];
  const onDelete = async (id) => {
    await dispatch(
      deleteCb({
        queryParams: id,
      })
    )
      .then(unwrapResult)
      .then(() => {
        setSnack(true);
        setMsg("Code Block Category Deleted!");
        setUpdateCodeBlocks(updateCodeBlocks + 1);
      });
  };
  const [editId, setEditId] = useState();
  const getCBById = async (editId) => {
    await dispatch(fetchCbById({ queryParams: editId }))
      .then(unwrapResult)
      .then((data) => {
        setData(data);
        setCode(data?.code);
      });
  };
  const onEdit = (id) => {
    getCBById(id);

    setEditMode(true);
    setOpenCodeBlock(true);
    setEditId(id);
  };
  return (
    <Box component={"main"}>
      <Container maxWidth={"xl"}>
        <Snackbar
          open={snack}
          onClose={() => setSnack(false)}
          autoHideDuration={5000}
        >
          <Alert
            onClose={() => setSnack(false)}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {msg}
          </Alert>
        </Snackbar>
        {/* form for code block  */}

        <Drawer
          open={openCodeBlock}
          anchor="right"
          onClose={() => setOpenCodeBlock(false)}
        >
          <div className="flex  ">
            <div className="w-full md:w-[600px] p-4 md:p-8  rounded-lg ">
              <div className="flex mb-6  justify-between items-center">
                <h2 className="text-md md:text-2xl font-bold text-center">
                  {edit ? "Edit" : "Create New"} Documentation Category
                </h2>
                <div
                  onClick={() => setOpenCodeBlock(false)}
                  className="w-[15px] h-[15px] "
                >
                  <svg
                    fill="#000000"
                    height="15px"
                    width="15px"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 490 490"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <polygon points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490 489.292,457.678 277.331,245.004 489.292,32.337 "></polygon>{" "}
                    </g>
                  </svg>
                </div>
              </div>
              <Formik
                initialValues={{
                  name: data?.name || "",
                  code: data?.code || "",
                  image: data?.image || "",

                  language: data?.language || "",
                  category: data?.category || "",
                  desc: data?.desc || "",
                  keywords: data?.keywords || "",
                  componentName: data?.componentName || "",
                }}
                validationSchema={formSchema}
                enableReinitialize={edit}
                onSubmit={async (values) => {
                  if (edit) {
                    await dispatch(
                      updateCb({ data: values, queryParams: editId })
                    ).then((data) => {
                      setSnack(true);
                      setMsg("Code Block Updated!");
                      setOpenCodeBlock(false);
                      setUpdateCodeBlocks(updateCodeBlocks + 1);
                    });
                  } else {
                    await dispatch(createCb({ data: values })).then((data) => {
                      setSnack(true);
                      setMsg("Code Block Added!");
                      setOpenCodeBlock(false);
                      setUpdateCodeBlocks(updateCodeBlocks + 1);
                    });
                  }
                }}
              >
                {({ errors, touched, setFieldValue, values }) => (
                  <Form>
                    <div className="mb-4">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-700"
                      >
                        Name
                      </label>
                      <Field
                        name="name"
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="mt-2 text-sm text-red-600"
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="desc"
                        className="block mb-2 text-sm font-medium text-gray-700"
                      >
                        Description
                      </label>
                      <Field
                        name="desc"
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                      <ErrorMessage
                        name="desc"
                        component="div"
                        className="mt-2 text-sm text-red-600"
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="componentName"
                        className="block mb-2 text-sm font-medium text-gray-700"
                      >
                        Component Name
                      </label>
                      <Field
                        name="componentName"
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                      <ErrorMessage
                        name="componentName"
                        component="div"
                        className="mt-2 text-sm text-red-600"
                      />
                    </div>
                    {data?.language !== "react" && (
                      <div className="mb-4">
                        <label
                          htmlFor="code"
                          className="block mb-2 text-sm font-medium text-gray-700"
                        >
                          Code
                        </label>
                        <JoditEditor
                          ref={editor}
                          value={data?.code || ""}
                          tabIndex={1}
                          addNewLine={false}
                          onBlur={(newContent) =>
                            setFieldValue("code", newContent)
                          }
                          onChange={(newContent) => {}}
                          className="border h-[300px] border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                        <ErrorMessage
                          name="code"
                          component="div"
                          className="mt-2 text-sm text-red-600"
                        />
                      </div>
                    )}
                    {data?.language === "react" && (
                      <div className="mb-4">
                        <label
                          htmlFor="code"
                          className="block mb-2 text-sm font-medium text-gray-700"
                        >
                          Code
                        </label>
                        <Editor
                          value={code}
                          onValueChange={(newContent) => {
                            setCode(newContent);
                            setFieldValue("code", newContent);
                          }}
                          highlight={(code) => highlight(code, languages.js)}
                          padding={10}
                          style={{
                            fontFamily: '"Fira code", "Fira Mono", monospace',
                            fontSize: 12,
                          }}
                          className="border h-[300px] border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                        <ErrorMessage
                          name="code"
                          component="div"
                          className="mt-2 text-sm text-red-600"
                        />
                      </div>
                    )}

                    <div className="mb-4">
                      <label
                        htmlFor="image"
                        className="block mb-2 text-sm font-medium text-gray-700"
                      >
                        Image
                      </label>
                      <ImageUpload
                        id="icon-button-file"
                        name="image"
                        setFieldValue={setFieldValue}
                        setSnack={setSnack}
                        setMsg={setMsg}
                        imgSrc={data?.image}
                      />

                      <ErrorMessage
                        name="image"
                        component="div"
                        className="mt-2 text-sm text-red-600"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="language"
                        className="block mb-2 text-sm font-medium text-gray-700"
                      >
                        Language
                      </label>
                      <Field
                        name="language"
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                      <ErrorMessage
                        name="language"
                        component="div"
                        className="mt-2 text-sm text-red-600"
                      />
                    </div>

                    <div className="mb-6">
                      <FormControl fullWidth>
                        <InputLabel htmlFor="category">Category</InputLabel>
                        <Field
                          name="category"
                          as={Select}
                          label="Category"
                          onChange={(event) => {
                            setFieldValue("category", event.target.value);
                          }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {uiKitCategories?.map((item) => {
                            return (
                              <MenuItem
                                key={item._id}
                                selected={data?.category === item._id}
                                value={item._id}
                              >
                                {item.name}
                              </MenuItem>
                            );
                          })}
                        </Field>
                      </FormControl>
                      <ErrorMessage
                        name="category"
                        component="div"
                        className="mt-2 text-sm text-red-600"
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="keywords"
                        className="block mb-2 text-sm font-medium text-gray-700"
                      >
                        Keywords
                      </label>
                      <Field
                        name="keywords"
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                      <ErrorMessage
                        name="keywords"
                        component="div"
                        className="mt-2 text-sm text-red-600"
                      />
                    </div>
                    <div className="flex items-center justify-start">
                      <button
                        type="submit"
                        className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      >
                        Add
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Drawer>
        <div className="flex items-center justify-between">
          <h3 className="text-lg md:text-3xl font-bold my-5">List Ui Kits </h3>
          <h6
            onClick={() => {
              setData();
              setEditMode(false);
              setOpenCodeBlock(true);
            }}
            className="font-bold rounded-md cp  text-white"
          >
            <img src="/add.svg" width={30} alt="" />
          </h6>
        </div>
        <TableComponent
          initialData={uiKits || []}
          headers={headers}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </Container>
    </Box>
  );
};
