import { Alert, Box, Container, Snackbar } from "@mui/material";

import TableComponent from "../../components/Table/TableComponent";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

import { deleteFile, getAll } from "../../redux/slices/fileUploadSlice";

export const DbStorage = () => {
  const [uiKits, setUiKits] = useState([{}]);
  const [updateCodeBlocks, setUpdateCodeBlocks] = useState(0);
  const dispatch = useDispatch();
  const [snack, setSnack] = useState(false);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    dispatch(getAll())
      .then(unwrapResult)
      .then((data) => {
        // Function to filter out excluded keys from an object

        const filterObject = (obj) => {
          return Object.keys(obj)
            .filter((key) => !excludeKeys.includes(key))
            .reduce((acc, key) => {
              acc[key] = obj[key];
              return acc;
            }, {});
        };

        // Map the data using the filterObject function
        setUiKits(
          data?.data?.map((filterObject, index) => {
            return { _id: index + 1, ...filterObject };
          })
        );
      });
  }, [updateCodeBlocks]);
  // Specify keys to exclude
  console.log(uiKits);

  const excludeKeys = [];
  const headers = [
    { id: "_id", label: "Id" },
    { id: "key", label: "Key" },
    { id: "lastModified", label: "Last Modified" },

    { id: "size", label: "Size" },
    { id: "url", label: "Image" },
  ];
  const onDelete = async (id) => {
    await dispatch(
      deleteFile({
        queryParams: id,
      })
    )
      .then(unwrapResult)
      .then(() => {
        setSnack(true);
        setMsg("Documentation Category Deleted!");
        setUpdateCodeBlocks(updateCodeBlocks + 1);
      });
  };

  const onEdit = (id) => {};
  return (
    <Box component={"main"}>
      <Container maxWidth={"xl"}>
        <Snackbar
          open={snack}
          onClose={() => setSnack(false)}
          autoHideDuration={5000}
        >
          <Alert
            onClose={() => setSnack(false)}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {msg}
          </Alert>
        </Snackbar>
        {/* form for code block  */}
        <div className="flex items-center justify-between">
          <h3 className="text-lg md:text-3xl font-bold my-5">List Media </h3>
        </div>
        {uiKits?.length ? (
          <TableComponent
            initialData={uiKits || []}
            headers={headers}
            onEdit={onEdit}
            onDelete={onDelete}
            needFile={true}
          />
        ) : (
          ""
        )}
      </Container>
    </Box>
  );
};
