import { Box, Typography } from "@mui/material";
import { useState } from "react";
import * as React from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";

import { useDispatch } from "react-redux";
import { getVideoSlugs } from "../redux/slices/videoSlice";
import { Link, useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";

export default function VideoDetails() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const dispatch = useDispatch();
  const [video, setVideo] = useState();
  const [others, setOthers] = useState();
  const params = useParams();
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  React.useEffect(() => {
    dispatch(
      getVideoSlugs({
        queryParams: `${params.catslug}/${params.slug}`,
      })
    )
      .then(unwrapResult)
      .then((data) => {
        if (data.status) {
          setVideo(data.video);
        }
      });
    dispatch(
      getVideoSlugs({
        queryParams: `${params.catslug}`,
      })
    )
      .then(unwrapResult)
      .then((data) => {
        if (data.status) {
          setOthers(data.video);
        }
      });
  }, [dispatch]);
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Typography
        component={"div"}
        fontSize={22}
        fontWeight={"bold"}
        className="ps-5 p-4 "
      >
        More {video?.category} Videos
      </Typography>
      <Divider />
      <List>
        {others?.map((text, index) => (
          <Link to={`/video/${text.categoryslug}/${text.slug}`} key={text}>
            <ListItem disablePadding>
              <ListItemButton>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 10,
                    width: "100%",
                  }}
                >
                  <div className="bg-indigo-600 p-1 w-full">
                    <img
                      className="w-full h-[130px] object-center"
                      src={text?.image}
                      alt={text?.title}
                    />
                  </div>
                  <p> {text?.title}</p>
                </div>
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <title>{video?.title}</title>
      <meta name="keywords" content={video?.keywords} />
      <meta name="description" content={video?.description} />
      <link rel="canonical" href={window.location.href} />

      <Box overflow={"hidden"} component={"main"} className="pt-12">
        <Box sx={{ display: "flex" }}>
          <Drawer
            anchor={"left"}
            style={{ position: "relative" }}
            variant="temporary"
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
            sx={{
              display: { xs: "none", sm: "block", zIndex: 0 },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: 255,
              },
            }}
          >
            {list("Open SideBar")}
          </Drawer>
          <Drawer
            style={{ position: "relative" }}
            anchor={"left"}
            onClose={toggleDrawer("left", false)}
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block", zIndex: 0 },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: 255,
                position: "relative",
              },
            }}
            open
          >
            {list("Open SideBar")}
          </Drawer>
          <Box
            width={"100%"}
            sx={{ padding: 2, paddingTop: 0, paddingBottom: 2 }}
          >
            <h1 className="text-4xl font-bold mb-6 text-indigo-600">
              {video?.title}
            </h1>
            <iframe
              className="w-full h-[500px]"
              src={video?.video_url}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>

            <div
              className="my-4"
              dangerouslySetInnerHTML={{ __html: video?.description }}
            ></div>
          </Box>
        </Box>
      </Box>
    </>
  );
}
