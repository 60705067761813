import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { removeToken } from "../redux/slices/userSlice";
import {
  Box,
  Container,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import ScrollToTopButton from "./ScrollTop";

const HeaderComponent = ({ open, setSidebar }) => {
  const [links, setLinks] = useState([
    { id: 1, name: "Ui Kits", link: "/ui-kits" },
    { id: 2, name: "Tutorials", link: "/tutorials" },
    { id: 3, name: "Documentations", link: "/documentations/all" },
    { id: 4, name: "Videos", link: "/videos" },
    {
      id: 5,
      name: "Interview Questions",
      link: "/interview-question-categories",
    },
    {
      id: 6,
      name: "Projects",
      link: "/projects",
    },
  ]);
  const pathname = useLocation().pathname;
  const user = useSelector((state) => state.user);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const drawer = (
    <Box
      sx={{
        width: 258,
        backgroundColor: "#4f46e5",
        color: "white",
        height: "100%",
      }}
      role="presentation"
      onClick={handleDrawerToggle}
      onKeyDown={handleDrawerToggle}
      className="shadow-md overflow-hidden"
    >
      <Box sx={{ p: 2, pt: 3 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Developer's Corner
        </Typography>
      </Box>
      <Box
        className="flex flex-col justify-between overflow-y-auto"
        style={{ height: "calc(100vh - 72px)" }}
      >
        <Box>
          <List>
            {links?.map((item) => (
              <ListItem
                button
                component={Link}
                to={item.link}
                key={item.id}
                sx={{
                  color: "white",
                  "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.1)" },
                }}
              >
                <ListItemText
                  primary={item.name}
                  primaryTypographyProps={{
                    fontSize: 18,
                    fontWeight: "medium",
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
        <Box className="pb-12 md:pb-0">
          <Divider sx={{ borderColor: "rgba(0, 0, 0, 0.12)" }} />
          <List>
            <div className="ms-auto flex justify-center items-center w-full mt-auto h-full">
              {!user.token ? (
                <>
                  <ListItem>
                    <Link to={"/login"}>
                      <img src="./login.svg" width={30} alt="login" />
                    </Link>

                    <Link to={"/register"} className="ms-4">
                      <img src="./register.png" width={30} alt="register" />
                    </Link>
                  </ListItem>
                </>
              ) : (
                <ListItem>
                  <Link
                    to={"#"}
                    onClick={() => {
                      dispatch(removeToken());
                      navigate("/");
                    }}
                  >
                    <img src="./logout.png" width={30} alt="logout" />
                  </Link>
                  {user?.role === "admin" ? (
                    <Link to={"/dashboard"} className="ms-4">
                      <img src="./dashboard.svg" width={30} alt="dashboard" />
                    </Link>
                  ) : (
                    ""
                  )}
                </ListItem>
              )}
            </div>
          </List>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Box
        sx={{
          backgroundColor: "white",
          boxShadow: 3,
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
        }}
      >
        <Container maxWidth={"xl"}>
          <div className="relative py-4">
            <nav
              className="relative flex items-center justify-between sm:h-10 lg:justify-start"
              aria-label="Global"
            >
              <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                <div className="flex items-center justify-between w-full lg:w-auto">
                  <Link to="/" className="text-2xl font-bold text-[#4f46e5]">
                    Developer's Corner
                  </Link>
                  <div className="-mr-2 flex gap-4 ms-auto items-center lg:hidden">
                    {pathname.includes("/dashboard") ? (
                      <div className="block lg:hidden">
                        <IconButton
                          onClick={() => {
                            open ? setSidebar(false) : setSidebar(true);
                          }}
                        >
                          <img
                            width={20}
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADYElEQVR4nO2ZT2xMURTGvwapLgSl2ogSEoJIWOlorQwLEhsRmqgqIRX/JUgTIdUaQq38DZFogukoFcHSn221HQk2FAkLqU2L0lq0zZM7Gcnk5Dvz7qjk3UnmS+7mvd+55zvv3bn3vjtATtmjMIDXAIYBeAG3YQCvAKzItIhZAH47UIAn2iCAmZkUstcB057SjDdrXXbAsKe0i5kU8lwERxCcIsLLk0yCv4rgGgSnrcLLF9vASeR1ViA4LSd+JtoELiOBxQhOxcRPmU3gNhHUT5g8AAOCeyqYTcSAuZaqZ2R6zSP5+gW3xaaQJhEUJ0wJMdnq80C85HhP1V3ClJB8LwVz2qaQxyIoRpgQMWCm7FTtJEytYK4QJkTy3RHMA5tCPoqgRsJUEgOS20+YfYI5SZhKkk9yb/2KGE/2Vmw81hEDBwRzmDCHBHOQMHUkX41ghgDkpytkMem4HMGrgvhamC6ADZkiBK9pxNe6dAEnBPwD7ui78HY0Hdwq4C7CjE2OUfmE5gruAmHOC2YeYYYAjCN5uwR3M10hbwTcQpg5JLlpUwR3jTBXBTNV6Ws2yRsTTKdWxBjyMdWgfDnKxCPJ+FQ1E66Z5BwhXJjkbRTMT2UXkBgassNqwu0gXC/hWggXJVwf4bYTrppwpayQtQQ0G0ipU4TrJtx9wrUR7j3hIoQrJ9wqVsgRApoxbPOk2y22Ol7ymtQLyzdXZLFTSOiGgMx055q++ezvEmoXUAfcU6fwaD7JfRcc9nqDVlR47JHAdDL+zCovtUCZ949ZrEle8sBP6rjS53zCNhBust/aUEU6WqMk3UXYbsK9I9xupc/VhN3sN7PusfzA0ZJuIOxnwn0i3MYMHk7I76vzksWWw+ickjRscaRExzSAlUqfTYRlW5oz6Q4BzGrL1KYkNd8xflOl1u8Spc97ige5E3iYerNH3DSLFCwOAf62GYQdJJw5eZEqVfqMKx46tF0FO5C7pXTCnrJpBYRlf0eYa1IFSp99iofbpM987UCuHu6qnvhdxM5W2UGaS6oiftebG2fJjaVwV2XagvyI3CiEuyokfs3vBh+UH1s2tTgc+bNztG0ADpj4Xw1BG8gVAvFG2ClGtrVfppDrDhgZbYuaQiYkTwB7HTDk/cNsFXN83cspJ7igP471YgjwopwbAAAAAElFTkSuQmCC"
                            alt="menu"
                          />
                        </IconButton>
                      </div>
                    ) : (
                      ""
                    )}
                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      onClick={handleDrawerToggle}
                    >
                      <svg
                        className="h-6 w-6 text-gray-900"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M4 6h16M4 12h16M4 18h16"
                        />
                      </svg>
                    </IconButton>
                  </div>
                </div>
              </div>
              <div className="hidden xl:block md:ml-10 md:pr-4 md:space-x-8">
                {links?.map((item) => (
                  <Link
                    key={item.id}
                    to={item.link}
                    className={`font-medium ${
                      pathname === item.link
                        ? "text-indigo-600"
                        : "text-gray-500"
                    } hover:text-indigo-400`}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
              <div className="ms-auto flex gap-3 hidden lg:block">
                {!user.token ? (
                  <>
                    <Link to={"/login"}>
                      <Button className="!bg-indigo-600" variant="contained">
                        Login
                      </Button>
                    </Link>
                    <Link to={"/register"} className="ms-3">
                      <Button className="!bg-indigo-600" variant="contained">
                        Register
                      </Button>
                    </Link>
                  </>
                ) : (
                  <div className="flex gap-3">
                    {pathname?.includes("/dashboard") ? (
                      ""
                    ) : (
                      <Link to={"/dashboard"}>
                        <Button className="!bg-indigo-600" variant="contained">
                          Dashboard
                        </Button>
                      </Link>
                    )}
                    <Link
                      to={"#"}
                      onClick={() => {
                        dispatch(removeToken());
                        navigate("/");
                      }}
                    >
                      <Button className="!bg-indigo-600" variant="contained">
                        Logout
                      </Button>
                    </Link>
                  </div>
                )}
              </div>
            </nav>
          </div>
        </Container>
      </Box>
      <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
        {drawer}
      </Drawer>
      <ScrollToTopButton />
    </>
  );
};

export default HeaderComponent;
