// LoginForm.js
import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  FaEnvelope,
  FaLock,
  FaEye,
  FaEyeSlash,
  FaSpinner,
} from "react-icons/fa";
import {
  loginUser,
  setRole,
  setToken,
  setUser,
} from "../redux/slices/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { Box, Container } from "@mui/material";
import CustomSnackbar from "../components/CustomSnackbar";

const LoginForm = () => {
  // State to toggle password visibility
  const [showPassword, setShowPassword] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [msg, setMsg] = useState(false);
  const [err, setErr] = useState("success");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  // Form submission handler
  const handleSubmit = async (values, { setSubmitting }) => {
    await dispatch(loginUser({ data: values }))
      .then(unwrapResult)
      .then((data) => {
        if (data?.status) {
          dispatch(setToken(data?.token));
          setMsg(data?.message);
          setSnackOpen(true);
          dispatch(
            setUser({
              name: data?.username,
              email: data?.email,
            })
          );
          dispatch(setRole(data?.role));
          setSubmitting(false);
          if (["learner", "company", "instructor"].includes(data?.role))
            navigate("/");
          else {
            navigate("/dashboard");
          }
        } else {
          setSubmitting(false);
          setSnackOpen(true);
          setMsg(data?.message);

          setErr("error");
        }
      });
  };

  return (
    <Box component={"main"}>
      <Container maxWidth={"xl"}>
        <CustomSnackbar
          open={snackOpen}
          onClose={() => setSnackOpen(false)}
          message={msg}
          severity={err}
        />
        <div className="flex justify-center items-center py-12  ">
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="sm:bg-white sm:p-8 rounded-lg sm:shadow-lg w-96">
                <h2 className="text-3xl font-bold mb-6 text-indigo-600">
                  Login
                </h2>
                <div className="mb-4 relative">
                  <label
                    htmlFor="email"
                    className="block text-gray-700 font-semibold mb-2"
                  >
                    Email
                  </label>
                  <div className="flex items-center border rounded-lg">
                    <FaEnvelope className="mx-3 text-gray-400" />
                    <Field
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Enter your email"
                      className="w-full px-3 py-2 outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                  </div>
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
                <div className="mb-6 relative">
                  <label
                    htmlFor="password"
                    className="block text-gray-700 font-semibold mb-2"
                  >
                    Password
                  </label>
                  <div className="flex items-center border rounded-lg relative">
                    <FaLock className="mx-3 text-gray-400" />
                    <Field
                      type={showPassword ? "text" : "password"}
                      id="password"
                      name="password"
                      placeholder="Enter your password"
                      className="w-full px-3 py-2 outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                    <button
                      type="button"
                      className="absolute right-3 text-gray-400 focus:outline-none"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  </div>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full bg-indigo-600 text-white py-3 px-4 rounded-lg font-semibold transition duration-300 ease-in-out hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:outline-none flex justify-center items-center"
                >
                  {isSubmitting ? (
                    <FaSpinner className="animate-spin mr-2" />
                  ) : (
                    "Login"
                  )}
                  {isSubmitting && "Logging in..."}
                </button>
                {/*  <div className="mt-4 text-center">
              <a href="#" className="text-sm text-indigo-600 hover:underline">
                Forgot your password?
              </a>
            </div> */}
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </Box>
  );
};

export default LoginForm;
