import { Box } from "@mui/material";
import { lazy, Suspense } from "react";
import HeroComponent from "../components/Home/Banner";
import { Loader } from "../components/Loader";

const Features = lazy(() => import("../components/Home/Features"));
const Testimonials = lazy(() => import("../components/Home/Testimonials"));
const Newsletter = lazy(() => import("../components/Home/Newsletter"));
const Docs = lazy(() => import("../components/Home/Docs"));

function Home() {
  const schemaOrgJSONLD = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Developer's Corner",
    url: "https://www.developerscorner.in",
    logo: "https://www.developerscorner.in/logo.png",
    sameAs: [
      "https://www.linkedin.com/in/navdeep-dahiya-719016186/",
      "https://instagram.com/heartblack1676",
      "https://www.youtube.com/@developerscorner",
    ],
  };

  return (
    <>
      <title>
        Developer's Corner: Software Development Tutorials, Courses, Projects
      </title>
      <meta
        name="description"
        content="Developer's Corner offers top-quality software development tutorials, courses, projects, UI kits, documentation, and Q&As to enhance your skills."
      />
      <link rel="preload" href="/hero.webp" as="image" />
      <link rel="canonical" href="https://www.developerscorner.in" />

      {/* Open Graph Meta Tags */}
      <meta
        property="og:title"
        content="Developer's Corner: Software Development Tutorials, Courses, Projects"
      />
      <meta
        property="og:description"
        content="Developer's Corner offers top-quality software development tutorials, courses, projects, UI kits, documentation, and Q&As to enhance your skills."
      />
      <meta
        property="og:image"
        content="https://www.developerscorner.in/hero.webp"
      />
      <meta property="og:url" content="https://www.developerscorner.in" />
      <meta property="og:type" content="website" />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="Developer's Corner: Software Development Tutorials, Courses, Projects"
      />
      <meta
        name="twitter:description"
        content="Developer's Corner offers top-quality software development tutorials, courses, projects, UI kits, documentation, and Q&As to enhance your skills."
      />
      <meta
        name="twitter:image"
        content="https://www.developerscorner.in/hero.webp"
      />

      {/* Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      <Box component="main">
        <HeroComponent />
        <Suspense fallback={<Loader />}>
          <Features />
          <Docs />
          <Testimonials />
          <Newsletter />
        </Suspense>
      </Box>
    </>
  );
}

export default Home;
