import React from "react";
import { TableHead, TableRow, TableCell, TableSortLabel } from "@mui/material";

const TableHeader = ({ headers, order, orderBy, onRequestSort }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow className="bg-indigo-600 text-white">
        {headers?.map((header) => (
          <TableCell
            key={header.id}
            sortDirection={orderBy === header.id ? order : false}
            className="font-semibold text-left text-white"
          >
            <TableSortLabel
              active={orderBy === header.id}
              direction={orderBy === header.id ? order : "asc"}
              onClick={createSortHandler(header.id)}
              className="text-white"
            >
              {header.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell className="font-semibold text-left text-white">
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
