import React, { useState, useMemo } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  Paper,
  TextField,
} from "@mui/material";
import TableHeader from "./TableHeader";
import TableRowData from "./TableRowData";

const TableComponent = ({
  initialData,
  headers,
  onDelete,
  onEdit,
  needFile,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const filteredData = useMemo(() => {
    return initialData.filter((row) =>
      Object.values(row).some(
        (value) =>
          value.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) !==
          -1
      )
    );
  }, [initialData, searchQuery]);

  const sortedData = useMemo(() => {
    if (orderBy) {
      return filteredData.sort((a, b) => {
        if (a[orderBy] < b[orderBy]) return order === "asc" ? -1 : 1;
        if (a[orderBy] > b[orderBy]) return order === "asc" ? 1 : -1;
        return 0;
      });
    }
    return filteredData;
  }, [filteredData, order, orderBy]);

  return (
    <TableContainer component={Paper} className="p-4 shadow-md">
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchQuery}
        onChange={handleSearch}
        className="mb-4"
      />
      <Table className="min-w-full bg-white">
        <TableHeader
          headers={headers}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {sortedData?.map((row, index) => {
            return (
              <TableRowData
                key={row._id}
                row={row}
                index={index}
                onEdit={() => onEdit(row._id)}
                onDelete={() => onDelete(needFile ? row.ObjectName : row._id)}
                needFile={needFile}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableComponent;
