import { Link } from "react-router-dom";

export default function UIKitCard({
  link,
  image,
  alt,
  categoryColor,
  category,

  title,
}) {
  return (
    <div className=" w-full  mb-6 md:md-0   border rounded-lg shadow-lg relative">
      <Link to={link} className="block">
        <div className="overlay"></div>
        <div className="w-full ">
          <img
            src={image}
            className=" aspect-[16/9]  w-100     rounded-lg shadow-none transition transition-shadow duration-500 ease-in-out group-hover:shadow-lg"
            alt={alt}
          />
        </div>
        <div className="flex flex-col justify-start items-start  p-2 absolute bottom-[2px] left-0">
          <span
            className={`inline-flex items-center px-3 py-0.5 rounded-full text-xs font-bold leading-5 text-white font-display mr-2 capitalize bg-${categoryColor}`}
          >
            {category}
          </span>
          <p className="font-display max-w-sm text-xl font-bold leading-tight">
            <span className="link-underline link-underline-black text-white">
              {`${title?.substr(0, 150)} ${title?.length < 150 ? "" : "..."}`}
            </span>
          </p>
        </div>
      </Link>
    </div>
  );
}
