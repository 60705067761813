import { Box, Container, Grid, Skeleton, Typography } from "@mui/material";
import UIKitCard from "../components/UiKits/Card";
import "react-multi-carousel/lib/styles.css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchDC } from "../redux/slices/docCatSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { fetchD, fetchDbyCatSlug } from "../redux/slices/documentationSlice";

function Documentations() {
  const dispatch = useDispatch();
  const params = useParams();
  const [data, setData] = useState([]);
  const [docs, setDocs] = useState([]);
  const [doccats, setDocCats] = useState([]);
  const [loading, setLoading] = useState(true);
  // Example data for courses
  useEffect(() => {
    dispatch(fetchDC())
      .then(unwrapResult)
      .then((data) => {
        if (data.status) {
          setData(data.allDoccat);
        }
      });
  }, [params.slug, dispatch]);
  const location = useLocation();
  useEffect(() => {
    console.log(params.slug);
    if (params.slug === "all") {
      dispatch(fetchD())
        .then(unwrapResult)
        .then((data) => {
          if (data.status) {
            setDocs(data.doc);
            setDocCats(data.doc);
            setLoading(false);
          }
        });
    }
  }, [params.slug, dispatch, location.pathname]);

  useEffect(() => {
    if (params.slug === "all") {
      return;
    }

    dispatch(fetchDbyCatSlug({ queryParams: params.slug }))
      .then(unwrapResult)
      .then((data) => {
        if (data.status) {
          setDocs(data.doc);
        }
      });
  }, [params.slug]);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const ids = doccats?.map((item) => item.categorySlug);

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Typography
        component={"div"}
        fontSize={22}
        fontWeight={"bold"}
        className="p-4 ps-0 pt-1"
      >
        {"Documentations"}
      </Typography>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton
            className={`${params.slug === "all" ? "!bg-[#0000000a]" : ""}`}
          >
            <ListItemText>
              <Link to={`/documentations/${"all"}`}>{"All"}</Link>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        {data?.map((text, index) => {
          if (ids.includes(text.slug)) {
            return (
              <ListItem key={text._id} disablePadding>
                <ListItemButton
                  className={`${
                    params.slug === text.slug ? "!bg-[#0000000a]" : ""
                  }`}
                >
                  <ListItemText>
                    <Link to={`/documentations/${text.slug}`}>
                      {text.title}
                    </Link>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            );
          }
        })}
      </List>
    </Box>
  );
  return (
    <>
      <title>Documentations</title>
      <meta name="keywords" content={"documentations, developer's corner"} />
      <link rel="canonical" href={window.location.href} />

      <Box component={"main"}>
        <Container maxWidth={"xl"} className="pt-12">
          <div className="text-center">
            <h2 className="text-3xl font-bold mb-4 text-[#4f46e5]">
              Documentatios
            </h2>
            <p className="text-lg mb-8">
              Explore our collection of UI kits designed to help you build
              stunning user interfaces effortlessly.
            </p>
          </div>
          <Box overflow={"hidden"}>
            <Box sx={{ display: "flex" }}>
              <Drawer
                style={{ position: "relative" }}
                anchor={"left"}
                onClose={toggleDrawer("left", false)}
                variant="permanent"
                sx={{
                  marginBottom: 6,
                  display: { xs: "none", sm: "block", zIndex: 0 },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: 255,
                    position: "relative",
                  },
                }}
                open
              >
                {list("Open SideBar")}
              </Drawer>
              <Box width={"100%"} className="xs:p-0 sm:px-4">
                {/* Mapping through courseData */}
                {loading ? (
                  <>
                    <Grid
                      container
                      spacing={3}
                      className="mt-3 mb-0"
                      justifyContent={"space-between"}
                    >
                      {Array?.from(new Array(4))?.map((item, index) => {
                        return (
                          <Grid key={index} item xs={12} sm={6} lg={4}>
                            <Skeleton
                              variant="rectangular"
                              className="w-full"
                              height={216}
                            />

                            <div className="p-3 pb-6 mb-6 bg-white rounded-lg shadow-lg">
                              <Skeleton />
                              <Skeleton width="60%" />
                            </div>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </>
                ) : (
                  <Grid
                    className="mt-4 mb-0 "
                    container
                    spacing={3}
                    justifyContent={"stretch"}
                  >
                    {docs?.map((card, cardIndex) => (
                      <Grid item key={cardIndex} xs={12} sm={12} md={6} lg={6}>
                        <UIKitCard
                          link={`/documentation/${card.slug}`}
                          image={card?.image}
                          alt={card?.slug}
                          categoryColor={"black"}
                          category={card?.category}
                          date={card?.createdAt}
                          title={card?.title}
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default Documentations;
