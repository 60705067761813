import { Box, Button, Container, Typography } from "@mui/material";
import { useState } from "react";
import * as React from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchT } from "../redux/slices/tutorialsSlice";
import { unwrapResult } from "@reduxjs/toolkit";
// Using ES6 import syntax
import hljs from "highlight.js";
import "highlight.js/styles/default.css";
import "../App.css";
import OverflowHiddenPage from "../components/OverFlowhidden";

export default function TutorialDetails() {
  const [data, setData] = useState();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch(fetchT({ queryParams: `${params.slug}/${params.topic}` }))
      .then(unwrapResult)
      .then((data) => {
        setData(data);
      });
  }, [params.topic]);

  React.useEffect(() => {
    if (data?.getATutData?.content) {
      setTimeout(() => {
        const codeBlocks = document.querySelectorAll("pre code");
        if (codeBlocks.length > 0) {
          hljs.highlightAll();
        }
      }, 300);
    }
  }, [data]);
  const currentIndex = data?.tutorialTopics?.findIndex(
    (topic) => topic.slug === params.topic
  );
  const goToPrevious = () => {
    if (currentIndex > 0) {
      const previousTopic = data?.tutorialTopics[currentIndex - 1];
      navigate(`/tutorial/${params.slug}/${previousTopic.slug}`);
      window.scrollTo({ top: 0, behavior: "smooth" });
      document
        .querySelector(".scroll-setter")
        .scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const goToNext = () => {
    if (currentIndex < data?.tutorialTopics.length - 1) {
      const nextTopic = data?.tutorialTopics[currentIndex + 1];
      navigate(`/tutorial/${params.slug}/${nextTopic.slug}`);
      window.scrollTo({ top: 0, behavior: "smooth" });
      document
        .querySelector(".scroll-setter")
        .scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      className="mb-[100px] "
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Typography
        component={"div"}
        fontSize={22}
        fontWeight={"bold"}
        className="ps-0 p-4 pt-1 text-[#4f46e5]"
      >
        {params.slug.toUpperCase() + " Tutorials"}
      </Typography>
      <Divider />
      <List>
        {data?.tutorialTopics?.map((text, index) => (
          <ListItem id={text.slug} key={text._id} disablePadding>
            <ListItemButton
              className={`${
                params.topic === text.slug ? "!bg-[#0000000a]" : ""
              }`}
            >
              <ListItemText>
                <Link to={`/tutorial/${params.slug}/${text.slug}`}>
                  {text.title}
                </Link>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <OverflowHiddenPage>
      <title>{data?.getATutData?.topicName}</title>
      <meta name="keywords" content={data?.getATutData?.keywords} />
      <link rel="canonical" href={window.location.href} />

      <Box overflow={"hidden"} component={"main"} className="pt-12">
        <Container maxWidth={"xl"}>
          <Box sx={{ display: "flex" }} className="bg-white">
            <Drawer
              style={{
                position: "relative",
              }}
              anchor={"left"}
              onClose={toggleDrawer("left", false)}
              variant="permanent"
              sx={{
                marginBottom: 6,
                display: { xs: "none", lg: "block", zIndex: 0 },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: 265,
                  position: "relative",
                  overflow: "scroll",
                  overflowX: "hidden",
                  height: "80vh",
                  background: "white",
                },
              }}
              open
            >
              {list("Open SideBar")}
            </Drawer>
            <Box
              width={"100%"}
              className="mb-10 p-0 sm:p-4 sm:pt-0  scroll-setter h-[80vh]"
            >
              <div
                className="pb-[10px]"
                dangerouslySetInnerHTML={{ __html: data?.getATutData?.content }}
              ></div>
              <div className="flex justify-between gap-4 mt-12 pb-[10px]">
                <Button
                  variant="contained"
                  className={`!text-white ${
                    currentIndex === 0 ? "!bg-blue-300" : "!bg-indigo-600"
                  } `}
                  onClick={goToPrevious}
                  disabled={currentIndex === 0}
                >
                  Previous
                </Button>
                <Button
                  variant="contained"
                  className={`!text-white ${
                    currentIndex === data?.tutorialTopics.length - 1
                      ? "!bg-blue-300"
                      : "!bg-indigo-600"
                  } `}
                  onClick={goToNext}
                  disabled={currentIndex === data?.tutorialTopics.length - 1}
                >
                  Next
                </Button>
              </div>
            </Box>
          </Box>
        </Container>
      </Box>
    </OverflowHiddenPage>
  );
}
