import { Box, Container, Grid } from "@mui/material";
import UIKitCard from "../components/UiKits/Card";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchP } from "../redux/slices/projectSlice";
import { unwrapResult } from "@reduxjs/toolkit";

function Projects() {
  const dispatch = useDispatch();
  const [projects, setProjects] = useState([]);
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    dispatch(fetchP())
      .then(unwrapResult)
      .then((data) => {
        if (data.status) {
          setProjects(data?.data);
          setCategories(data?.data?.map((item) => item?.category));
        }
      });
  }, [dispatch]);
  console.log(projects);

  return (
    <Box component={"main"}>
      <Container maxWidth={"xl"} className="pt-10">
        <div className={`text-center`}>
          <h2 className="text-3xl font-bold mb-4 text-[#4f46e5]">Projects</h2>
          <p className="text-lg mb-8">
            Explore our collection of UI kits designed to help you build
            stunning user interfaces effortlessly.
          </p>
        </div>

        {/* Mapping through courseData */}
        {categories?.map((category, index) => (
          <div key={index} className="tag-container my-5">
            <h3 className="text-xl font-bold mb-3">{category}</h3>

            <Grid container spacing={3} justifyContent={"stretch"}>
              {projects
                .filter((item) => item?.category === category)
                ?.map((card, cardIndex) => (
                  <Grid item key={cardIndex} sm={6} lg={4}>
                    <UIKitCard
                      link={`/projects/${card?.slug}`}
                      image={card?.images[0]}
                      alt={card?.alt}
                      categoryColor={"black"}
                      category={card?.category}
                      date={card?.updatedAt}
                      title={card?.title}
                    />
                  </Grid>
                ))}
            </Grid>
          </div>
        ))}
      </Container>
    </Box>
  );
}

export default Projects;
